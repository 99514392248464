import styled, { css } from 'styled-components';
import { size } from 'polished';
import { device, sizes } from '../../../style/util/media-query';
import { aspectRatio } from '../../../style/util/aspect-ratio';
import { fullWidthMargin, fullWidthMarginMobile } from '../../../style/util/full-width';
import Dialogue from '../Dialogue/Dialogue';
import { TabsVariantType } from '../../../data/type/content';
import { Heading } from '../../general/Heading/Heading';
import { Paragraph } from '../../general/Paragraph/Paragraph';
import { innerColumnOffset } from '../../../style/util/grid';
import { transition } from '../../../style/util/transition';
import ShapeLine from '../../general/ShapeLine/ShapeLine';
import { zIndex } from '../../../style/util/z-index';
import { pseudo } from '../../../style/util/pseudo';

export const transitionInDuration = 0.6;
export const transitionOutDuration = 0.4;

export const StyledTabContent = styled.div<{ $reverse?: boolean; $mounted: boolean }>`
  position: relative;
  border-top: 0.1rem solid transparent;

  @media ${device.desktop} {
    display: grid;
    gap: ${({ theme }) => theme.gridSpacing};
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    border-top: initial;

    ${({ $reverse }) =>
      $reverse &&
      `
        .column:nth-child(1) {
          order: 1;
        }

        .column:nth-child(3) {
          order: -1;
        }
    `};
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 1fr);

    .column {
      grid-column: span 4;
    }
  }

  @media ${device.desktopM} {
    .column {
      :nth-child(1),
      :nth-child(3) {
        grid-column: span 3;
      }

      :nth-child(2) {
        grid-column: span 6;
      }
    }
  }
`;

/* Copy Container */
export const StyledTitle = styled(Heading)`
  color: ${({ theme }) => theme.color.blue};
  margin-bottom: 1rem;

  @media ${device.tablet} {
    margin-top: 2rem;
  }
`;

export const StyledHeading = styled(Heading)<{ $addUnderline: boolean }>`
  display: inline-block;
  color: ${({ theme }) => theme.color.blue};

  @media ${device.desktop} {
    font-size: 2.6rem;

    ${({ theme, $addUnderline }) =>
      $addUnderline &&
      `
      padding-top: 1.3em;

      &::after {
        ${pseudo('inline-block', 'relative')};
        width: 100%;
        bottom: 2rem;
        border-bottom: 0.3rem solid ${theme.color.yellow};
      }
    `};
  }

  @media ${device.desktopM} {
    font-size: 3.2rem;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color.ozarkNoir70};
  margin-top: 0.8rem;

  @media ${device.desktop} {
    font-size: 1.8rem;
    margin-top: 1.6rem;
  }

  @media ${device.desktopM} {
    font-size: 2rem;
  }
`;

export const StyledLine = styled(ShapeLine)`
  display: none;
  transform: translateX(0);
  pointer-events: none;

  @media ${device.desktop} {
    display: block;
    bottom: 100%;

    &.lineArrow2 {
      width: 76.35%;
      right: 0;
      transform: translateX(23%) translateY(-8%);
    }

    &.curlyLine7 {
      width: 110%;
      left: 0;
      transform: translateX(-47%) translateY(-10%);
    }

    &.curlyLine8 {
      width: 125%;
      right: 0;
      transform: translateX(-5%) translateY(0%);
    }
  }

  @media ${device.desktopM} {
    &.curlyLine8 {
      width: 164%;
      transform: translateX(22%) translateY(0%);
    }
  }
`;

/* Horizontal tab copy style */
const copyContainerHorizontal = css`
  margin-top: calc(80vw + 2.4rem);

  @media ${device.tablet} {
    margin-top: calc(57.15vw + 3rem);
  }

  @media ${device.desktop} {
    margin-top: 0;

    ${StyledHeading},
    ${StyledParagraph} {
      .horizontal:not(.reversed) & {
        padding-right: 2.1vw;
      }

      .horizontal.reversed & {
        padding-left: 2.1vw;
        width: 105%;
      }
    }
  }

  @media ${device.desktopXl} {
    ${StyledHeading},
    ${StyledParagraph} {
      padding-right: 4.8rem;
    }
  }
`;

/* Vertical tab copy style */
const copyContainerVertical = css`
  @media (max-width: ${sizes.desktop - 1}px) {
    ${StyledHeading} {
      color: ${({ theme }) => theme.color.ozarkNoir};
    }
  }

  @media ${device.desktop} {
    ${StyledTitle} {
      display: none;
    }
  }
`;

export const StyledCopyContainer = styled.div<{ $variant: TabsVariantType; $delay: number }>`
  ${({ $variant }) =>
    $variant === 'horizontal' ? copyContainerHorizontal : copyContainerVertical};
  position: relative;
  text-align: center;

  &::before {
    ${fullWidthMargin};
  }

  ${transition({ outDuration: transitionOutDuration })};

  @media (max-width: ${sizes.desktop - 1}px) {
    padding: 0 14%;
    margin-bottom: 4rem;
  }

  @media ${device.desktop} {
    text-align: left;
  }
`;

/* Content image */
const figureHorizontal = css`
  ${aspectRatio(375, 300)};
  position: absolute;
  top: 0;
  ${zIndex('background')};
  ${transition({ inDelay: 0.1, outDuration: transitionOutDuration })};
  transform: none;

  @media ${device.tablet} {
    ${aspectRatio(1.75, 1, false)};
  }

  @media ${device.desktop} {
    ${aspectRatio(1, 1.5)};
    position: relative;
    margin: 0;
    transform: translateY(2rem);
  }

  @media ${device.desktopM} {
    ${aspectRatio(1, 1)};
  }
`;

const figureVertical = css`
  ${aspectRatio(1, 1)};
  position: relative;
  margin-top: 2rem;

  img {
    border-bottom-right-radius: 6rem;
  }

  @media ${device.tablet} {
    ${aspectRatio(1.25, 1, false)};
  }

  @media ${device.desktop} {
    ${aspectRatio(1, 1.34)};
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const StyledFigure = styled.figure<{
  $variant: TabsVariantType;
  $addOffset?: boolean;
  $hasBubble?: boolean;
}>`
  ${fullWidthMarginMobile};
  ${({ $variant }) => ($variant === 'horizontal' ? figureHorizontal : figureVertical)};
  ${({ $variant, $hasBubble }) => $variant === 'vertical' && $hasBubble && 'margin-bottom: -8rem;'};

  img {
    ${size('100%')};
    position: absolute;
    top: 0;
    object-fit: cover;
  }

  @media ${device.desktop} {
    ${({ theme, $variant, $addOffset }) =>
      $variant === 'vertical' &&
      `margin-left: ${innerColumnOffset(theme.gridSpacing, 1, 4, 0, $addOffset ? -1 : 1)};
      `};
    width: 100%;

    img {
      border-bottom-right-radius: 6rem;
    }
  }
`;

/* Dialogues */
export const StyledDialogue = styled(Dialogue)<{ $addOffset: boolean }>`
  max-width: 42rem;
  margin: 0 auto;

  @media ${device.desktop} {
    max-width: none;

    > div {
      &:first-of-type:not(:last-of-type) {
        ${({ $addOffset }) => $addOffset && `margin-left: -5rem;`};
      }
    }
  }
`;
