import { ReactElement, Fragment } from 'react';
import Cards from '../block/Cards/Cards';
import useGlobal from '../../store';
import { useLoadContent } from '../../hooks/use-load-content';
import HeroSecondary from '../block/HeroSecondary/HeroSecondary';
import Faq from '../block/Faq/Faq';

export default function FaqPage(): ReactElement {
  const [{ faq }] = useGlobal();
  useLoadContent('faq');

  if (!faq) {
    return <></>;
  }

  return (
    <Fragment key="faq">
      {faq.hero && <HeroSecondary {...faq.hero} image={undefined} />}
      {faq.faq && <Faq {...faq.faq} />}
      {faq && faq.cards && <Cards {...faq.cards} variant="secondary" />}
    </Fragment>
  );
}
