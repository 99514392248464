export type Page = 'home' | 'faq' | 'about' | 'siri' | 'googleAssistant';

// eslint-disable-next-line no-shadow
export enum TempoPageType {
  Home = 'HomePage',
  Faq = 'FAQ',
  Siri = 'SiriPage',
  GoogleAssistant = 'GoogleAssistantPage',
  About = 'About'
}

export interface TempoPage {
  pageType: TempoPageType;
  contentZone?: Record<string, string>;
}

export const tempoPages: { [key in Page]: TempoPage } = {
  home: {
    pageType: TempoPageType.Home
  },
  faq: {
    pageType: TempoPageType.Faq
  },
  siri: {
    pageType: TempoPageType.Siri
  },
  googleAssistant: {
    pageType: TempoPageType.GoogleAssistant
  },
  about: {
    pageType: TempoPageType.About
  }
};
