import { size } from 'polished';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { innerColumnOffset } from '../../../style/util/grid';
import { device, sizes } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { Icon } from '../../general/Icon/Icon';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { pseudo } from '../../../style/util/pseudo';

export const StyledSection = styled(StyledBaseSection)`
  background-color: ${({ theme }) => theme.color.greyLight};
`;
export const StyledSectionInner = styled(StyledBaseSectionInner)`
  @media ${device.desktop} {
    display: grid;
    gap: ${({ theme }) => theme.gridSpacing};
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
`;

export const StyledIcon = styled(Icon)`
  ${size('4.4rem')};
  position: relative;
  margin: 0 auto 6.5rem;

  @media ${device.desktop} {
    display: none;
  }
`;

export const StyledHeading = styled.div`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 4.5rem;

  @media ${device.desktop} {
    margin-bottom: 1rem;
    text-align: left;
  }
`;

const sliderShader = css`
  height: 100%;
  top: 0;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.color.greyLight};
  pointer-events: none;
`;

export const StyledSliderContainer = styled.div`
  @media ${device.desktop} {
    position: relative;
    width: ${({ theme }) => `calc(${theme.sitePaddingHorizontal} + 100%)`};
    padding-right: ${({ theme }) => `${theme.sitePaddingHorizontal}`};
    height: auto;
    overflow: hidden;

    &::after {
      ${pseudo()};
      ${sliderShader};
      width: ${({ theme }) => `${theme.sitePaddingHorizontal}`};
      right: 0;
    }
  }
`;

export const StyledSlider = styled(Slider)`
  @media ${device.desktop} {
    width: ${({ theme }) => innerColumnOffset(theme.gridSpacing, 5, 6)};

    .slick-list {
      overflow: visible;
      cursor: grab;
    }

    .slick-track {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const StyledSlideControls = styled.div`
  display: none;

  @media ${device.desktop} {
    display: flex;
    justify-content: left;
    margin-top: 8rem;
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${sizes.tablet - 1}px) {
    ${size('4rem')};
  }

  @media ${device.desktop} {
    &:first-of-type {
      margin-right: 1.6rem;
    }
  }
`;

export const StyledSideImage = styled.div`
  display: none;
  position: relative;

  @media ${device.desktop} {
    display: block;
    grid-column: 1 / 7;
    width: 30rem;
  }

  @media ${device.desktopM} {
    grid-column: 2 / 7;
  }
`;

export const StyledStepImage = styled.img`
  position: absolute;
  object-fit: cover;

  .siri & {
    ${size('96%', '91%')};
    left: 4.5%;
    top: 2%;
    border-radius: 2rem;
  }

  .google-assistant & {
    ${size('86.5%', '88%')};
    left: 5.5%;
    top: 7.2%;
  }
`;

export const StyledPhoneImage = styled.img`
  ${size('auto', '100%')};
  position: relative;
`;

export const StyledSectionInnerText = styled.div`
  @media ${device.desktop} {
    position: relative;
    grid-column: 7 / -1;

    &::after {
      ${pseudo()};
      ${sliderShader};
      width: ${({ theme }) => innerColumnOffset(theme.gridSpacing, 1, 6, -1)};
      right: 0;
    }
  }
`;
