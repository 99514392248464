import styled from 'styled-components';
import { HeadingType, typeStyle } from '../../../style/type/type-style';
import type { StyledTextProps } from '../Paragraph/Paragraph.styled';

/**
 * These types are available for the Heading component. Keep in mind that by default the type also
 * defines the tag that is used to render out the component. So if you choose to use the type `h2`
 * it will also render out a `<h2>` tag.
 */
interface StyledHeadingProps extends StyledTextProps {
  $type: HeadingType;
}

export const StyledHeading = styled.h1<StyledHeadingProps>`
  ${({ $type }) => typeStyle[$type]};
  ${({ $textAlign }) => $textAlign && `text-align: ${$textAlign};`};
  ${({ $color }) => $color && `color: ${$color};`};
  ${({ $fontWeight }) => $fontWeight && `font-weight: ${$fontWeight};`};
  ${({ theme, $textTransform }) =>
    theme.supportsTextCasing && $textTransform && `text-transform:  ${$textTransform}`};
`;
