import styled, { css } from 'styled-components';
import { size } from 'polished';
import { device, sizes } from '../../../style/util/media-query';
import { Icon } from '../../general/Icon/Icon';
import Shape from '../../general/Shape/Shape';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { Paragraph } from '../../general/Paragraph/Paragraph';
import Card from '../../content/Card/Card';
import { VariantType } from '../../../data/type/content';
import { cardWidthMobile } from '../../content/Card/Card.styled';

export const StyledSection = styled(StyledBaseSection)`
  background-color: ${({ theme }) => theme.color.blue};

  @media ${device.desktop} {
    overflow: hidden;
  }
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)<{ $variant: VariantType }>`
  ${({ $variant }) => $variant === 'primary' && `text-align: center;`};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;

  @media (max-width: ${sizes.desktop - 1}px) {
    max-width: 33.5rem;
  }

  @media ${device.desktop} {
    display: grid;
    gap: ${({ theme }) => theme.gridSpacing};
    grid-template-columns: repeat(12, 1fr);
    ${({ $variant }) => ($variant === 'primary' ? `` : `align-items: center;`)};
  }
`;

export const StyledTitleContainer = styled.div<{ $variant: VariantType }>`
  text-align: center;
  width: 100%;
  margin-bottom: 4rem;

  @media ${device.desktop} {
    ${({ $variant }) =>
      $variant === 'primary'
        ? `grid-column: 3 / span 8; margin-bottom: 4.6rem;`
        : `grid-column: 1 / span 4; text-align: left;`};
  }

  @media ${device.desktopM} {
    ${({ $variant }) => $variant === 'secondary' && `grid-column: 1 / span 3;`};
  }
`;

export const StyledEyebrow = styled(Paragraph)`
  margin-bottom: 1.1rem;

  @media ${device.desktop} {
    margin-bottom: 1.5rem;
  }
`;

const primary = css`
  &:nth-of-type(3) {
    margin: 3rem 0 0 calc(100% - ${cardWidthMobile});
  }

  @media ${device.desktop} {
    &:nth-of-type(2) {
      grid-column-start: 3;
      margin: 0 0 4rem -${({ theme }) => theme.gridSpacing};
    }

    &:nth-of-type(3) {
      grid-column-start: 7;
      margin: 4rem 0 0 ${({ theme }) => theme.gridSpacing};
    }
  }

  @media ${device.desktopM} {
    &:nth-of-type(2) {
      grid-column-start: 4;
    }
  }
`;

const secondary = css`
  &:nth-of-type(3) {
    margin: 3rem 0 0 calc(100% - ${cardWidthMobile});
  }

  @media ${device.desktop} {
    &:nth-of-type(2) {
      grid-column-start: 5;
      margin-bottom: 4rem;
    }

    &:nth-of-type(3) {
      grid-column-start: 9;
      margin: 4rem 0 0;
    }
  }

  @media ${device.desktopM} {
    &:nth-of-type(2) {
      grid-column-start: 5;
    }

    &:nth-of-type(3) {
      grid-column-start: 9;
    }
  }
`;

const cardMap: Record<VariantType, typeof primary> = {
  primary,
  secondary
};

export const StyledCard = styled(Card)<{ $variant: VariantType }>`
  ${({ $variant }) => cardMap[$variant]};
  color: ${({ theme }) => theme.color.ozarkNoir};
  text-align: left;

  @media ${device.desktop} {
    grid-column-end: span 4;
  }

  @media ${device.desktopM} {
    grid-column-end: span 3;
  }
`;

export const StyledBackground = styled.div`
  ${size('100%')};
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledShape = styled(Shape)`
  display: none;
  position: absolute;

  @media ${device.desktop} {
    display: block;
    ${size('13rem')};
  }

  @media ${device.desktopM} {
    ${size('15rem')};
  }
`;

export const StyledShapeWatermelonTop = styled(StyledShape)`
  left: 0;
  top: 0;
`;

export const StyledShapeWatermelonBottom = styled(StyledShape)`
  right: 0;
  bottom: 0;

  @media ${device.desktop} {
    display: none;
  }

  @media ${device.desktopM} {
    display: block;
  }
`;

export const StyledShapePizza = styled(StyledShape)`
  top: 0;

  @media ${device.desktop} {
    left: 13rem;
  }

  @media ${device.desktopM} {
    left: 15rem;
  }
`;

export const StyledShapeMacarron = styled(StyledShape)`
  bottom: 0;

  @media ${device.desktop} {
    width: 6.5rem;
  }

  @media ${device.desktopM} {
    width: 7.5rem;
  }
`;

export const StyledShapeMacarronWhite = styled(StyledShapeMacarron)`
  @media ${device.desktop} {
    right: 6.5rem;
  }

  @media ${device.desktopM} {
    right: 22.5rem;
  }
`;

export const StyledShapeMacarronYellow = styled(StyledShapeMacarron)`
  @media ${device.desktop} {
    right: 0;
  }

  @media ${device.desktopM} {
    right: 15rem;
  }
`;

const StyledLineDesktop = styled(Icon)`
  display: none;

  svg {
    transform: translateY(-50%);
  }

  @media ${device.tablet} {
    display: block;
    position: absolute;
  }
`;

export const StyledLineArrow = styled(StyledLineDesktop)`
  top: 17%;
  right: 0;

  @media ${device.tablet} {
    width: 15rem;
  }

  @media ${device.desktopM} {
    width: 18rem;
  }
`;

export const StyledCurlyVector = styled(StyledLineDesktop)`
  top: 30%;
  right: 0;

  @media ${device.tablet} {
    width: 23rem;
  }

  @media ${device.desktopM} {
    width: 26.8rem;
  }
`;

export const StyledLineVector = styled(StyledLineDesktop)`
  top: 41%;

  @media ${device.tablet} {
    width: 20rem;
    left: -4%;
  }

  @media ${device.desktopM} {
    width: 26.8rem;
    left: 0;
  }
`;

export const StyledCurlyVector2 = styled(StyledLineDesktop)`
  top: 58%;
  color: ${({ theme }) => theme.color.yellow};

  @media ${device.tablet} {
    width: 20rem;
    left: -1%;
  }
  @media ${device.desktopM} {
    width: 26.8rem;
    left: 0;
  }
`;

export const StyledLine = styled(StyledLineDesktop)`
  ${size('6.25vw', '100%')};
  color: ${({ theme }) => theme.color.yellow};
  top: 56%;
  transform: translateY(-50%);

  svg {
    ${size('100%')};
  }
`;

const StyledVectorMobile = styled(Icon)`
  position: absolute;
  left: 0;
  width: 100%;

  svg {
    transform: translateY(-50%);
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export const StyledLineVectorMobile = styled(StyledVectorMobile)`
  top: 22rem;
`;

export const StyledCurlyVectorMobile = styled(StyledVectorMobile)`
  top: 55rem;
`;
