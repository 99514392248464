import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import {
  StyledSection,
  StyledSectionInner,
  StyledTabsContainer,
  StyledTabBarWrapper,
  StyledTabBar,
  StyledTitleVertical,
  StyledArrow,
  StyledTabContentContainer,
  StyledTabContent,
  StyledBackground,
  StyledLine
} from './TabsVertical.styled';
import { TabsProps } from '../TabsHorizontal/TabsHorizontal';
import { addClass } from '../../../util/element';

gsap.registerPlugin(ScrollToPlugin);

const TabsVertical: React.FC<TabsProps> = ({ title, storyGroup: list }) => {
  const [tabList, setTabList] = useState<Array<string | null>>([]);
  const tabBarRef = useRef<HTMLDivElement>(null);
  const tabContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTabList(
      list.map(({ title: tabTitle }) => (typeof tabTitle === 'string' ? tabTitle : tabTitle.title))
    );
  }, [list]);

  const tabToScroll = (index: number) => {
    const element = tabContentRef.current?.querySelectorAll(`.tab-content`)[index];
    if (element) {
      const { top, height } = element.getBoundingClientRect();
      const scrollTo = window.scrollY + top - (window.innerHeight - height) * 0.5;
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo,
        duration: 0.5
      });
    }
  };

  const onInViewChange = (inViewIndex: number, inView: boolean): void => {
    if (tabBarRef.current) {
      tabBarRef.current.querySelectorAll('button').forEach((button, index) => {
        const visible = inView ? index === inViewIndex : index === inViewIndex - 1;
        addClass(button, 'selected', visible);
      });
    }
  };

  return (
    <StyledSection>
      <StyledBackground>
        <StyledLine icon="curlyLine1" />
        <StyledLine icon="curvedLine3" />
        <StyledLine icon="curlyLine9" />
        <StyledLine icon="curvedLine3" />
      </StyledBackground>
      <StyledSectionInner>
        <StyledTitleVertical type="h2">{title}</StyledTitleVertical>
        <StyledArrow name="arrowDown" />
        <StyledTabsContainer>
          <StyledTabBarWrapper ref={tabBarRef}>
            <StyledTabBar list={tabList} variant="vertical" tabToScroll={tabToScroll} />
          </StyledTabBarWrapper>
          <StyledTabContentContainer ref={tabContentRef}>
            {list.map((content, index) => (
              <StyledTabContent
                content={content}
                index={index}
                variant="vertical"
                reverse={index % 2 === 0}
                onInViewChange={onInViewChange}
                key={content.subtitle}
              />
            ))}
          </StyledTabContentContainer>
        </StyledTabsContainer>
      </StyledSectionInner>
    </StyledSection>
  );
};

export default TabsVertical;
