import { css } from 'styled-components';
import { size } from 'polished';
import { device } from './media-query';
import { pseudo } from './pseudo';

export const curvedHeight = '3.5rem';
export const curvedHeightMobile = '2rem';

export const fullHeightWithCurved = css`
  height: calc(100vh - var(--vh-offset) + ${curvedHeightMobile});
  padding-bottom: ${curvedHeightMobile};
  margin-bottom: -${curvedHeightMobile};
  overflow: hidden;

  // Curved bottom
  &::before {
    ${pseudo()};
    ${size('100%', '130%')};
    background-color: ${({ theme }) => theme.color.greyLight};
    border-bottom-left-radius: 100% 7rem;
    border-bottom-right-radius: 100% 7rem;
    top: 0;
    left: -15%;
  }

  @media ${device.desktop} {
    height: calc(100vh - var(--vh-offset) + ${curvedHeight});
    padding-bottom: ${curvedHeight};
    margin-bottom: -${curvedHeight};

    &::before {
      border-bottom-left-radius: 100% 18rem;
      border-bottom-right-radius: 100% 18rem;
    }
  }
`;
