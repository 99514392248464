import { css, FlattenSimpleInterpolation } from 'styled-components';
import { easeOut } from 'polished';

const transitionDuration = 0.8;

export interface TransitionProps {
  inDuration?: number;
  outDuration?: number;
  inDelay?: number;
  outDelay?: number;
  y?: number;
  selector?: string;
}

export interface TransitionFromProps {
  duration?: number;
  delay?: number;
  y?: number;
  ease?: string;
  additional?: string;
}

export interface TransitionToProps {
  opacity?: number;
  delay?: number;
  filter?: boolean;
  duration?: number;
}

export const transitionFrom = ({
  duration = transitionDuration,
  delay = 0,
  y = 2,
  ease = 'cubic',
  additional = ''
}: TransitionFromProps = {}): FlattenSimpleInterpolation => css`
  opacity: 0;
  transform: translateY(${y}rem);
  transition: opacity ${duration}s ${delay}s,
    transform ${duration}s ${easeOut(ease)} ${delay}s ${additional};
`;

export const transitionTo = ({
  opacity = 1,
  delay = 0,
  filter = false,
  duration = transitionDuration
}: TransitionToProps = {}): FlattenSimpleInterpolation => css`
  opacity: ${opacity};
  transform: none;
  ${filter && `filter: none;`};
  ${duration && `transition-duration: ${duration}s;`};
  ${delay && `transition-delay: ${delay}s;`};
`;

export const transition = ({
  inDuration = transitionDuration,
  outDuration = transitionDuration * 0.6,
  inDelay = 0,
  outDelay = 0,
  y = 2,
  selector = '.transition-in'
}: TransitionProps = {}): FlattenSimpleInterpolation => css`
  ${transitionFrom({ duration: outDuration, delay: outDelay, y })};

  ${selector} & {
    ${transitionTo({ duration: inDuration, delay: inDelay })};
  }
`;
