import styled from 'styled-components';
import { size } from 'polished';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { device } from '../../../style/util/media-query';
import { Icon } from '../../general/Icon/Icon';

export const StyledSection = styled(StyledBaseSection)`
  display: flex;
  align-items: center;
  min-height: 90vh;
  background-color: ${({ theme }) => theme.color.blue};

  @media ${device.desktop} {
    min-height: 48vw;
  }
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  height: 100%;

  .title {
    color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledShapeContainer = styled.div`
  ${size('100%')};
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export const StyledLine = styled(Icon)`
  position: absolute;
  color: ${({ theme }) => theme.color.yellow};

  svg path {
    stroke-width: 5;
  }

  &:nth-of-type(1) {
    ${size('11.4rem', '15.4rem')};
    top: 0;
    left: 0;
    transform: translateX(-42%) translateY(-15%);
  }

  &:nth-of-type(2) {
    ${size('15.4rem', '14.4rem')};
    top: 0;
    right: 0;
    transform: rotate(180deg) translateX(-42%) translateY(42%);
  }

  &:nth-of-type(3) {
    ${size('15.4rem', '14.4rem')};
    bottom: 0;
    left: 0;
    transform: translateX(-14%) translateY(10%);
  }

  &:nth-of-type(4) {
    ${size('15rem', '18rem')};
    bottom: 0;
    right: 0;
    transform: translateX(27%) translateY(10%);
  }

  @media ${device.desktop} {
    transform: none !important;

    &:nth-of-type(1) {
      ${size('9.3vw', '23vw')};
      top: 15%;
    }

    &:nth-of-type(2) {
      ${size('6.67vw', '15.4vw')};
      top: 50%;
    }

    &:nth-of-type(3) {
      ${size('5.42vw', '20vw')};
      top: 40%;
    }

    &:nth-of-type(4) {
      ${size('5.42vw', '16vw')};
      top: 67%;
    }
  }
`;
