import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { size } from 'polished';
import { Icon } from '../Icon/Icon';
import { device } from '../../../style/util/media-query';
import type { ShapeColor } from './Shape';
import { icons } from '../../../data/icons';

export const shapeWidthMobile = '9.6rem';
export const shapeWidth = '15rem';

export const StyledShapeSize = (isHalf?: boolean): FlattenSimpleInterpolation => css`
  ${size(shapeWidthMobile)};
  ${isHalf && `width: calc(${shapeWidthMobile} * 0.5)`};
  flex-shrink: 0;

  @media ${device.desktop} {
    ${size(shapeWidth)};
    ${isHalf && `width: calc(${shapeWidth} * 0.5)`};
  }
`;

export const StyledIcon = styled(Icon)<{
  $color: ShapeColor;
  $icon: keyof typeof icons;
  $isHalf: boolean;
  $noStroke: boolean;
  $rotation?: string;
}>`
  ${({ $isHalf }) => StyledShapeSize($isHalf)};
  color: ${({ theme, $color }) => theme.color[$color === 'ozarkNoir' ? 'blue' : 'ozarkNoir']};

  svg {
    ${({ $rotation }) => $rotation && `transform: rotate(${$rotation});`};
    ${({ $icon }) =>
      $icon === 'shapeApple' &&
      `
       transform: scale(1.05) translateY(-2%);
    `};
  }

  .shape {
    fill: ${({ theme, $color }) => theme.color[$color]};
  }

  .stroke {
    ${({ $noStroke }) => $noStroke && `display: none;`};
  }
`;
