import React, { useContext, useEffect, useState } from 'react';
import {
  StyledModalContainer,
  StyledModalBackdrop,
  StyledModalContent,
  transitionDuration
} from './ModalContainer.styled';
import { ModalContext } from '../../../../context/ModalContext';
import ChooseAssistant from '../../../content/ChooseAssistant/ChooseAssistant';

const ModalContainer: React.FC = () => {
  const [mounted, setMounted] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 10);

    // Prevent scrolling on mount
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setIsClosing(false);
        closeModal();
      }, transitionDuration * 1000);
    }
  }, [closeModal, isClosing]);

  const handleCloseClick = () => {
    setIsClosing(true);
  };

  return (
    <StyledModalContainer aria-modal aria-hidden tabIndex={-1} role="dialog">
      <StyledModalBackdrop $mounted={mounted && !isClosing} onClick={handleCloseClick} />
      <StyledModalContent $mounted={mounted && !isClosing}>
        {/* adding this module directly since we only have one module */}
        <ChooseAssistant handleCloseClick={handleCloseClick} />
      </StyledModalContent>
    </StyledModalContainer>
  );
};

export default ModalContainer;
