import { useEffect, useState } from 'react';
import useGlobal, { StoreActions, StoreState } from '../store';
import { AboutState, FaqState, HomeState, SetupState } from '../store/module/app';
import { Page } from '../data/enum/tempo';

export const useLoadContent = (page: Page): boolean => {
  const [value, setValue] = useGlobal<
    HomeState | FaqState | SetupState | AboutState | null,
    (pageName: string, signal: AbortSignal) => Promise<{ success: boolean }>
  >(
    (state: StoreState) => state[page],
    (actions: StoreActions) => actions.fetchContent
  );

  const [hasContent, setHasContent] = useState<boolean>(!!value);

  useEffect(() => {
    if (value) {
      return undefined;
    }

    const controller = new AbortController();
    // load contents from tempo
    setValue(page, controller.signal).then(({ success }) => {
      setHasContent(success);
    });
    return () => {
      if (controller) {
        controller.abort();
      }

      setHasContent(false);
    };
  }, [page, setValue, value]);

  return hasContent;
};
