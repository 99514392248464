import styled from 'styled-components';
import { size } from 'polished';
import { device } from '../../style/util/media-query';

export interface StyledBaseSectionInnerProps {
  $padding?: string;
  $paddingDesktop?: string;
}

export const StyledBaseSection = styled.section`
  ${size('auto', '100%')};
  position: relative;
  padding: 0 ${({ theme }) => theme.sitePaddingHorizontalMobile};
  overflow-x: hidden;

  @media ${device.desktop} {
    padding: 0 ${({ theme }) => theme.sitePaddingHorizontal};
  }
`;

export const StyledBaseSectionInner = styled.div<StyledBaseSectionInnerProps>`
  ${size('auto', '100%')};
  position: relative;
  margin: 0 auto;
  padding: ${({ $padding }) => $padding || '6.4rem 0'};
  max-width: ${({ theme }) => theme.sectionInnerMaxWidth};

  @media ${device.desktop} {
    padding: ${({ $paddingDesktop }) => $paddingDesktop || '8.33vw 0'};
  }

  @media ${device.desktopL} {
    padding: ${({ $paddingDesktop }) => $paddingDesktop || '12rem 0'};
  }

  // Add extra space if it follows the hero section with curved bottom
  .hero-image + section &,
  .hero-home + section & {
    padding: 10rem 0;

    @media ${device.desktop} {
      padding: calc(8.33vw + 3rem) 0 12rem;
    }

    @media ${device.desktopL} {
      padding-top: 15rem;
    }
  }
`;
