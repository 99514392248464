import type React from 'react';
import { SyntheticEvent, useRef } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import { StyledFaqAnchors, StyledSlider, StyledFaqAnchor } from './FaqAnchors.styled';

export interface AnchorOption {
  label: string;
  index: number;
}

interface FaqAnchorsProp {
  anchors: Array<AnchorOption>;
  faqToScroll?: (index: number) => void;
  className?: string;
}

const FaqAnchors: React.FC<FaqAnchorsProp> = ({ anchors, faqToScroll, className }) => {
  const slickRef = useRef<Slider>(null);

  const responsive: Array<ResponsiveObject> = [
    {
      breakpoint: 9999,
      settings: 'unslick'
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
      }
    }
  ];

  const settings = {
    arrows: false,
    infinite: false,
    initialSlide: 0,
    responsive
  };

  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLElement>) => {
    if (faqToScroll) {
      const index = parseInt(currentTarget.dataset.anchorIndex || '0', 10);
      faqToScroll(index);
    }
  };

  return (
    <StyledFaqAnchors className={className}>
      <StyledSlider {...settings} ref={slickRef}>
        {anchors.map(({ index, label }) => (
          <StyledFaqAnchor
            key={label}
            buttonColor="greyLight"
            data-anchor-index={`${index}`}
            onClick={handleClick}
          >
            {label}
          </StyledFaqAnchor>
        ))}
      </StyledSlider>
    </StyledFaqAnchors>
  );
};

export default FaqAnchors;
