import React, { createContext } from 'react';

interface ModalContextData {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const initialModalContextData: ModalContextData = {
  isModalOpen: false,
  openModal: () => {
    throw new Error('ModalContext was not yet initialized');
  },
  closeModal: () => {
    throw new Error('ModalContext was not yet initialized');
  }
};

export const ModalContext = createContext<ModalContextData>(initialModalContextData);

type ModalContextProviderProps = {
  children: React.ReactNode;
};

type ModalContextProviderState = {
  isModalOpen: boolean;
};

export class ModalContextProvider extends React.Component<
  ModalContextProviderProps,
  ModalContextProviderState
> {
  public props: ModalContextProviderProps;
  public state: ModalContextProviderState;

  public constructor(props: ModalContextProviderProps) {
    super(props);
    this.props = props;
    this.state = {
      isModalOpen: false
    };
  }

  public closeModal(): void {
    this.setState({ isModalOpen: false });
  }
  public openModal(): void {
    this.setState({ isModalOpen: true });
  }

  public render(): JSX.Element {
    return (
      <ModalContext.Provider
        value={{
          ...this.state,
          openModal: this.openModal.bind(this),
          closeModal: this.closeModal.bind(this)
        }}
      >
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}
