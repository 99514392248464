import { ReactElement, Fragment } from 'react';
import HeroSecondary from '../block/HeroSecondary/HeroSecondary';
import Cards from '../block/Cards/Cards';
import TabsVertical from '../block/TabsVertical/TabsVertical';
import useGlobal from '../../store';
import { useLoadContent } from '../../hooks/use-load-content';

export default function AboutPage(): ReactElement {
  const [{ about }] = useGlobal();
  useLoadContent('about');

  if (!about) {
    return <></>;
  }

  return (
    <Fragment key="about">
      {about.hero && <HeroSecondary {...about.hero} />}
      {about.tabs && <TabsVertical {...about.tabs} />}
      {about.cards && <Cards {...about.cards} />}
    </Fragment>
  );
}
