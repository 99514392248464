import { Store } from 'use-global-hook';
import type { StoreActions, StoreState } from '..';
import { Page, tempoPages } from '../../data/enum/tempo';
import type { CardsProps } from '../../components/block/Cards/Cards';
import type { FooterProps } from '../../components/layout/Footer/Footer';
import type { HeroHomeProps } from '../../components/block/HeroHome/HeroHome';
import type { TabsProps } from '../../components/block/TabsHorizontal/TabsHorizontal';
import type { KeyMessageProps } from '../../components/block/KeyMessage/KeyMessage';
import type { TestimoniesProps } from '../../components/block/Testimonies/Testimonies';
import type { FaqProps } from '../../components/block/Faq/Faq';
import { HeroSecondaryProps } from '../../components/block/HeroSecondary/HeroSecondary';
import { BannerProps } from '../../components/block/Banner/Banner';
import { StepperProps } from '../../components/block/Stepper/Stepper';
import { DevicesProps } from '../../components/block/Devices/Devices';
import { CopyContent, TempoNavigationGroup } from '../../data/type/content';
import type { NavigationGroup } from '../../components/layout/Navigation/Navigation';

type PageState = {
  navigation?: NavigationGroup | null;
  footer?: FooterProps | null;
  modal?: CopyContent | null;
};

export type HomeState = PageState & {
  hero?: HeroHomeProps | null;
  keyMessage?: KeyMessageProps | null;
  tabs?: TabsProps | null;
  cards?: CardsProps | null;
  testimonies?: TestimoniesProps | null;
  faq?: FaqProps | null;
};

export type FaqState = PageState & {
  hero: HeroSecondaryProps | null;
  faq?: FaqProps | null;
  cards?: CardsProps | null;
};

export type SetupState = PageState & {
  hero: HeroSecondaryProps | null;
  tabs?: TabsProps | null;
  setup?: StepperProps | null;
  banner?: BannerProps | null;
  devices?: DevicesProps | null;
};

export type AboutState = PageState & {
  hero: HeroSecondaryProps | null;
  tabs?: TabsProps | null;
  cards?: CardsProps | null;
};

export type AppState = {
  debug: string;
  home: HomeState | null;
  faq: FaqState | null;
  siri: SetupState | null;
  googleAssistant: SetupState | null;
  about: AboutState | null;
};

// initialState
const initialState: AppState = {
  debug: '',
  home: null,
  faq: null,
  siri: null,
  googleAssistant: null,
  about: null
};

// actions
const fetchContent = (
  store: Store<StoreState, StoreActions>,
  page: Page,
  signal: AbortSignal
): Promise<{ success: boolean }> => {
  const url = `${process.env.REACT_APP_TEMPO_API || '/local-debug/'}${tempoPages[page].pageType}${
    !process.env.REACT_APP_TEMPO_API ? '.json' : ''
  }`;

  return fetch(url, {
    signal,
    method: 'GET'
  }).then(async (response) => {
    try {
      const result = await response.json();
      if (result.modules) {
        const data: Record<string, unknown> = {};
        result.modules.forEach(
          ({ name, configs }: { name: string; configs: Record<string, unknown> }) => {
            // get key from module name in Tempo
            const key = name.includes('-') ? name.split('-')[1] : name;
            // remap top navigation
            if (key === 'navigation') {
              const nav: Record<string, unknown> = {};
              (configs as unknown as TempoNavigationGroup).linkGroup.forEach(
                ({ groupName, links }) => {
                  nav[groupName] = [...links];
                }
              );
              data[key] = { ...nav };
            } else {
              data[key] = { ...configs };
            }
          }
        );

        store.setState({
          ...store.state,
          [page]: {
            ...data
          }
        });

        return { success: true };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  });
};

export default {
  actions: {
    fetchContent
  },
  initialState
};
