import { useEffect, useState } from 'react';

export const useIsMobileSize = (): boolean => {
  const [isMobileSize, setIsSizeMobile] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    const setMediaMatchHandler = (event: MediaQueryListEvent | MediaQueryList): void => {
      setIsSizeMobile(event.matches);
    };

    const mediaQueryList = window.matchMedia('(max-width: 1023px)');
    mediaQueryList.addListener(setMediaMatchHandler);
    setMediaMatchHandler(mediaQueryList);

    return () => {
      mediaQueryList.removeListener(setMediaMatchHandler);
    };
  }, []);

  return isMobileSize;
};
