import { Route, Routes } from 'react-router-dom';
import Navigation from './layout/Navigation/Navigation';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import FaqPage from './pages/FaqPage';
import SetupPage from './pages/SetupPage';
import Footer from './layout/Footer/Footer';
import ScrollToTop from './general/ScrollToTop/ScrollToTop';
import { Modal } from './layout/modal/Modal/Modal';
import RotateYourPhone from './layout/RotateYourPhone/RotateYourPhone';

export default function App(): JSX.Element {
  return (
    <>
      <ScrollToTop />
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/siri" element={<SetupPage />} />
        <Route path="/google-assistant" element={<SetupPage />} />
      </Routes>
      <Footer />
      <RotateYourPhone />
      <Modal />
    </>
  );
}
