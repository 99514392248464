import styled from 'styled-components';
import { size } from 'polished';
import { device } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { Heading } from '../../general/Heading/Heading';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { Paragraph } from '../../general/Paragraph/Paragraph';

export const StyledSection = styled(StyledBaseSection)`
  background-color: ${({ theme }) => theme.color.greyLight};
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  @media ${device.desktop} {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 1.2rem 0;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.grey};

  @media ${device.desktop} {
    font-size: 2rem;
    padding-bottom: 2.4rem;
  }
`;

export const StyledWrapper = styled.ul`
  @media ${device.desktop} {
    display: grid;
    gap: ${({ theme }) => theme.gridSpacing};
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const StyledLinks = styled.li`
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;

  @media ${device.desktop} {
    grid-column-end: span 4;
  }

  @media ${device.desktopM} {
    grid-column-end: span 3;
  }
`;

export const StyledLink = styled(Button)`
  display: block;
  height: auto;
  margin: 0.6rem 0;

  @media ${device.desktop} {
    margin: 1.2rem 0;
  }
`;

export const StyledWalmartRights = styled.div`
  color: ${({ theme }) => theme.color.blue};
  margin: 6rem 0 2.5rem;
  padding-bottom: 3rem;

  @media ${device.desktopM} {
    text-align: right;
    margin-top: -10rem;
    padding-bottom: 0;
  }
`;

export const StyledSocialButton = styled(Button)`
  ${size('4rem')};
  margin-right: 1.25rem;

  @media ${device.desktopM} {
    margin-right: 0;
    margin-left: 1.25rem;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: 1.6rem;
`;
