import Slider from 'react-slick';
import styled from 'styled-components';
import { device, hover } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';

export const StyledFaqAnchors = styled.div``;

export const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    cursor: grab;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    position: relative;
    flex-shrink: 0;
    padding-right: 1rem;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      width: auto;
    }
  }
`;

export const StyledFaqAnchor = styled(Button)`
  height: 4.8rem;
  border-radius: 2.4rem;
  padding: 0 2rem;

  &.selected {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blue};

    @media ${hover} {
      &:hover {
        background-color: ${({ theme }) => theme.color.blue};
      }
    }
  }

  @media ${device.desktop} {
    width: 100%;
    margin: 1.2rem 0;
  }

  @media ${device.desktopM} {
    span {
      font-size: 2rem;
    }
  }
`;
