import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { size, rgba } from 'polished';
import { pseudo } from '../../../style/util/pseudo';
import { zIndex } from '../../../style/util/z-index';
import type { AvatarCircleColor, AvatarIllustration, SpeechBubbleAlign } from './Avatar';
import SpeechBubble from '../SpeechBubble/SpeechBubble';
import { avatarPulseInner, avatarPulseOuter } from '../../../style/util/animation';
import { device } from '../../../style/util/media-query';
import { Icon } from '../Icon/Icon';

export const linearGradient = (color = 'white'): FlattenSimpleInterpolation => css`
  linear-gradient(
    125deg,
    ${rgba(color, 0.24)} -32%,
    ${rgba(color, 0.06)} 104%
  )`;

export const StyledAvatar = styled.div`
  margin-top: -0.5em;
  margin-left: -0.5em;
  font-size: 9rem;

  @media ${device.desktop} {
    font-size: 12rem;
  }
`;

const circles = (color: string, useGradient: boolean, setScale: boolean = false) => css`
  &::before,
  &::after {
    ${pseudo()};
    ${size('100%')};
    top: 0;
    left: 0;
    border-radius: 50%;
    background: ${useGradient ? linearGradient(color) : rgba(color, 0.2)};
  }

  ${setScale &&
  `
  &::before {
    transform: scale(1.34);
  }

  &::after {
    transform: scale(1.68);
    opacity: 0.8;
  }
 `};
`;

const duration = 1.8;

export const StyledFigure = styled.figure<{
  $color: AvatarCircleColor;
  $useGradient: boolean;
  $isActive: boolean;
}>`
  ${size('1em')};
  position: relative;
  font-size: inherit;
  ${({ theme, $color, $useGradient }) => circles(theme.color[$color], $useGradient, true)};

  &::before,
  &::after {
    transition: transform 0.9s;
  }

  &.pulse {
    &::before,
    &::after {
      transition: transform 0.4s;
    }

    &::before {
      transform: scale(0.7);
    }

    &::after {
      transform: scale(0.5);
    }
  }
`;

export const StyledCircles = styled.span<{
  $color: AvatarCircleColor;
  $useGradient: boolean;
  $out: boolean;
}>`
  ${size('100%')};
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  ${({ theme, $color, $useGradient }) => circles(theme.color[$color], $useGradient)};

  ${({ $out }) =>
    $out &&
    `
    transform: scale(0.5);
    transition: transform 0.8s;
  `};

  &::before {
    animation: ${avatarPulseInner} ${duration}s ease-out infinite forwards;
  }

  &::after {
    animation: ${avatarPulseOuter} ${duration}s ease-out infinite forwards;
  }
`;

export const StyledCirclesLoop = styled.span<{
  $color: AvatarCircleColor;
  $useGradient: boolean;
  $isActive: boolean;
}>``;

export const StyledImage = styled.img`
  ${size('100%')};
  ${zIndex('content')};
  position: relative;
  object-fit: cover;
  border-radius: 50%;
`;

export const StyledSpeechBubble = styled(SpeechBubble)<{
  $isActive: boolean;
  $align: SpeechBubbleAlign;
}>`
  position: absolute;
  display: flex;
  top: 1.1em;
  ${({ $align }) => ($align === 'left' ? `left: 0.35em;` : `right: 0.25em;`)};
  ${({ $isActive }) => !$isActive && `visibility: hidden;`};

  @media ${device.desktopM} {
    ${({ $align }) =>
      $align === 'left' ? `top: 1.1em; left: 0.75em;` : `top: 1em; right: 0.92em;`};
  }
`;

/* illustrations */
const bananas = css`
  ${size('2.4em', '1.86em')};
  top: -0.375em;
  left: 23%;

  @media (min-aspect-ratio: 16/10) {
    top: auto;
    bottom: -21vh;
  }
`;

const coffee = css`
  ${size('3.38em', '2.62em')};
  top: -2em;
  left: 0;
`;

const illustrationMap: Record<AvatarIllustration, FlattenSimpleInterpolation> = {
  bananas,
  coffee
};

export const StyledIllustration = styled(Icon)<{ $icon: AvatarIllustration; $isActive: boolean }>`
  ${zIndex('content')};
  ${({ $icon }) => illustrationMap[$icon]};
  position: absolute;
  display: none;

  @media ${device.desktop} {
    display: block;
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    transition: opacity 1s;
  }
`;
