import React, { useEffect, useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { TempoCta } from '../../../data/type/content';
import {
  StyledHeading,
  StyledSection,
  StyledSectionInner,
  StyledLinks,
  StyledWrapper,
  StyledLink,
  StyledSocialButton,
  StyledWalmartRights,
  StyledParagraph
} from './Footer.styled';
import useGlobal, { StoreState } from '../../../store';
import { icons } from '../../../data/icons';
import { toCamel } from '../../../util/string';
import { Page } from '../../../data/enum/tempo';

interface FooterList {
  groupName: string;
  links: Array<{ link: TempoCta }>;
}

export interface FooterProps {
  linkGroup: Array<FooterList>;
  subtitle?: string;
}

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const [, setMounted] = useState(false);
  const page = (toCamel(pathname.split('/')[1]) || 'home') as Page;
  const [currentFooter] = useGlobal<FooterProps | null | undefined, undefined>(
    (state: StoreState) => state[page]?.footer,
    () => undefined
  );

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 100);
  }, []);

  if (!currentFooter) {
    return <></>;
  }

  return (
    <StyledSection>
      <StyledSectionInner>
        <StyledWrapper>
          {currentFooter.linkGroup.map(
            ({ groupName, links }) =>
              groupName !== 'social icons' && (
                <StyledLinks key={groupName}>
                  <StyledHeading type="h5">{groupName}</StyledHeading>
                  {links.map(
                    ({
                      link: {
                        linkText,
                        clickThrough: { value: href }
                      }
                    }) => (
                      <StyledLink
                        buttonTheme="tertiary"
                        buttonColor="dark"
                        href={href}
                        key={`${groupName}-${linkText}`}
                      >
                        {linkText}
                      </StyledLink>
                    )
                  )}
                </StyledLinks>
              )
          )}
        </StyledWrapper>
        <StyledWalmartRights>
          {currentFooter.linkGroup.map(
            ({ groupName, links }) =>
              groupName === 'social icons' && (
                <Fragment key={groupName}>
                  <div>
                    {links.map(
                      ({
                        link: {
                          linkText,
                          clickThrough: { value: href }
                        }
                      }) => (
                        <StyledSocialButton
                          icon={linkText as keyof typeof icons}
                          key={`${groupName}-${linkText}`}
                          href={href}
                        />
                      )
                    )}
                  </div>
                  <StyledParagraph size="small">
                    {currentFooter?.subtitle || '© 2022 Walmart. All Rights Reserved'}
                  </StyledParagraph>
                </Fragment>
              )
          )}
        </StyledWalmartRights>
      </StyledSectionInner>
    </StyledSection>
  );
};

export default Footer;
