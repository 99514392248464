import styled from 'styled-components';
import { size } from 'polished';
import { device, sizes } from '../../../style/util/media-query';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { Heading } from '../../general/Heading/Heading';
import { pseudo } from '../../../style/util/pseudo';
import { fullWidthMargin } from '../../../style/util/full-width';
import TabContent from '../../content/TabContent/TabsContent';
import TabBar from '../../content/TabBar/TabBar';
import { Button } from '../../general/buttons/Button/Button';

export const StyledSection = styled(StyledBaseSection)`
  @media (max-width: ${sizes.desktop - 1}px) {
    overflow: visible;
  }
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)``;

export const StyledTitle = styled(Heading)`
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.color.blue};
  max-width: 30rem;

  @media ${device.desktop} {
    max-width: 75%;
    font-size: 4rem;
  }

  @media ${device.desktopM} {
    font-size: 5rem;
  }
`;

export const StyledTabsContainer = styled.div`
  width: 100%;
`;

const tabBarHeight = '8rem';
const stickyTabBarMarginMobile = '80vw';
const stickyTabBarMarginTablet = '57vw';

export const StyledTabBarContainer = styled.div`
  height: calc(${stickyTabBarMarginMobile} + ${tabBarHeight});

  @media ${device.tablet} {
    height: calc(${stickyTabBarMarginTablet} + ${tabBarHeight});
  }

  @media ${device.desktop} {
    height: auto;
    margin: 1.5rem 0 2.5rem;
  }
`;

export const StyledTabBar = styled(TabBar)`
  @media (max-width: ${sizes.desktop - 1}px) {
    ${fullWidthMargin};
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${tabBarHeight};
    top: 0;

    &::before {
      ${pseudo()};
      ${size('100%')};
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledTabContent = styled(TabContent)`
  margin-top: calc(-${stickyTabBarMarginMobile} - 1rem);
  pointer-events: none;

  @media ${device.tablet} {
    margin-top: calc(-${stickyTabBarMarginTablet} - 0.2rem);
  }

  @media ${device.desktop} {
    margin-top: 0;
    pointer-events: auto;
  }
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.4rem;

  @media ${device.tablet} {
    margin-top: 4.8rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  @media ${device.tablet} {
    width: auto;
    margin: 0 auto;
  }

  @media ${device.desktop} {
    display: none;
  }
`;
