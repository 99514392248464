import { css, FlattenSimpleInterpolation } from 'styled-components';
import { theme } from '../theme';

export const innerColumnOffset = (
  gridSpacing: string,
  offsetWanted = 0,
  columnsTotal = 12,
  extraGridSpacing = 0,
  multiplier = 1
): string => {
  const columnWidth = `calc((100% - (${columnsTotal - 1} * ${gridSpacing})) / ${columnsTotal})`;
  const offset = `calc(
    (${columnWidth} * ${offsetWanted}) + ${offsetWanted} * ${gridSpacing} + ${extraGridSpacing} * ${gridSpacing}
  )`;
  return `calc(${offset} * ${multiplier})`;
};

export const grid = (
  columnsTotal = 12,
  gridSpacing = theme.gridSpacing
): FlattenSimpleInterpolation => css`
  display: grid;
  gap: ${gridSpacing};
  grid-template-columns: repeat(${columnsTotal}, 1fr);
`;
