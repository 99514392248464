import React, { useEffect, useRef, useState } from 'react';
import {
  StyledTabContent,
  StyledCopyContainer,
  StyledTitle,
  StyledHeading,
  StyledParagraph,
  StyledLine,
  StyledFigure,
  StyledDialogue,
  transitionOutDuration
} from './TabContent.styled';
import { TabsVariantType, TempoStoryWithBubble } from '../../../data/type/content';
import { SpeechBubble } from '../Dialogue/Dialogue';
import { useInView } from '../../../hooks/use-in-view';

export type LineIcon = 'lineArrow2' | 'curlyLine7' | 'curlyLine8';

export interface Content {
  title: string;
  heading: string;
  description?: string;
  image: string;
  speechBubbles: Array<SpeechBubble>;
}

export interface TabContentProps {
  content: TempoStoryWithBubble;
  index?: number;
  onInViewChange?: (index: number, inView: boolean) => void;
  reverse?: boolean;
  variant?: TabsVariantType;
  line?: LineIcon;
  className?: string;
}

const TabContent: React.FC<TabContentProps> = ({
  content,
  index = 0,
  onInViewChange,
  reverse = false,
  variant = 'horizontal',
  line,
  className = ''
}) => {
  const [transitionIn, setTransitionIn] = useState(variant === 'vertical');
  const [currentContent, setCurrentContent] = useState<TempoStoryWithBubble>(content);
  const [currentLine, setCurrentLine] = useState<LineIcon | undefined>(line);
  const [isReversed, setIsReversed] = useState<boolean>(reverse);
  const timerRef = useRef<number>();
  const [containerRef, isInView] = useInView({ start: 'top 50%', forwardOnly: true });

  useEffect(() => {
    if (onInViewChange) {
      onInViewChange(index, isInView);
    }
  }, [index, isInView, onInViewChange]);

  useEffect(() => {
    if (variant === 'vertical') {
      return undefined;
    }

    setTransitionIn(false);
    timerRef.current = window.setTimeout(() => {
      setCurrentContent(content);
      setCurrentLine(line);
      setIsReversed(reverse);
      setTransitionIn(true);
    }, transitionOutDuration * 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [content, line, reverse, variant]);

  return (
    <StyledTabContent
      $reverse={isReversed}
      className={`${className} ${variant} ${isReversed ? 'reversed' : ''} ${
        transitionIn ? 'transition-in' : ''
      } tab-content`}
      $mounted={transitionIn}
      ref={variant === 'vertical' ? containerRef : null}
    >
      <StyledCopyContainer className="column" $variant={variant} $delay={0}>
        {currentLine && <StyledLine icon={currentLine} />}
        {variant === 'vertical' && (
          <StyledTitle type="h5">
            {typeof currentContent.title === 'string'
              ? currentContent.title
              : currentContent.title.title}
          </StyledTitle>
        )}
        <StyledHeading
          type="h4"
          $addUnderline={variant === 'horizontal' && !currentContent.description && !isReversed}
        >
          {currentContent.subtitle}
        </StyledHeading>
        {currentContent.description && (
          <StyledParagraph>{currentContent.description}</StyledParagraph>
        )}
      </StyledCopyContainer>
      <StyledFigure
        $variant={variant}
        $addOffset={isReversed}
        $hasBubble={currentContent.bubbleGroup && currentContent.bubbleGroup.length > 0}
        className="column"
      >
        <img
          src={currentContent.image.src}
          alt={currentContent.image.alt || currentContent.image.title}
        />
      </StyledFigure>
      <StyledDialogue
        speechBubbles={currentContent.bubbleGroup}
        variant={variant}
        enableBackdropFilter
        transitionOut={
          variant === 'horizontal' ? currentContent.title !== content.title : !isInView
        }
        transitionOutDuration={transitionOutDuration * 0.75}
        delay={variant === 'horizontal' ? 0.15 : 0}
        className="column"
        $addOffset={variant === 'horizontal' && !isReversed}
      />
    </StyledTabContent>
  );
};

export default TabContent;
