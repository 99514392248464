import { rgba, size } from 'polished';
import styled from 'styled-components';
import { device, hover } from '../../../../style/util/media-query';
import { pseudo } from '../../../../style/util/pseudo';
import { Button } from '../../../general/buttons/Button/Button';
import { Heading } from '../../../general/Heading/Heading';

export const StyledItem = styled.li`
  ${pseudo('block', 'relative')};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.grey};
  padding: 1.7rem 0;

  @media ${device.desktop} {
    padding: 3.1rem 0;
  }
`;

export const StyledHeading = styled(Heading)`
  width: calc(100% - 6rem);

  @media ${device.desktop} {
    width: 80%;
    min-width: 52rem;
  }
`;

export const StyledItemTab = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: ${({ $active }) => ($active ? '700' : '400')};
  letter-spacing: ${({ $active }) => ($active ? '-0.009em' : '0')};

  ${StyledHeading} {
    font-weight: inherit;
  }

  @media ${hover} {
    &:hover {
      font-weight: 700;
      letter-spacing: -0.009em;
    }
  }
`;

export const StyledExpanded = styled.div`
  width: 80%;
  height: 0;
  overflow: hidden;

  > p:first-child {
    padding-top: 2rem;
  }
`;

export const StyledToggleButton = styled(Button)<{ $active: boolean }>`
  ${size('4rem')};
  transition: transform 0.3s, border-color 0.2s;
  ${({ $active }) => $active && `transform: rotate(90deg);`};

  svg {
    path {
      &:last-of-type {
        transition: opacity 0.2s;
        ${({ $active }) => $active && `opacity: 0;`};
      }
    }
  }

  @media ${hover} {
    ${StyledItem}:hover & {
      background-color: ${({ theme }) => rgba(theme.color.ozarkNoir, 0.1)};
      border-color: ${({ theme }) => rgba(theme.color.ozarkNoir, 0)};
      color: ${({ theme }) => theme.color.ozarkNoir};
    }
  }
`;
