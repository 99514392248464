import type React from 'react';
import type { FunctionComponent } from 'react';
import { StyledIconWrapper } from './Icon.styled';
import { icons } from '../../../data/icons';

export interface IconProps {
  name: keyof typeof icons;
  as?: 'div' | 'span';
  type?: 'div' | 'span';
  ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  enableHoverAnim?: boolean;
}

export const Icon: FunctionComponent<IconProps> = ({
  name,
  as,
  type,
  ref,
  className,
  enableHoverAnim = false
}) => {
  const SelectedIcon = icons[name];

  if (!SelectedIcon) {
    throw new Error(`Icon "${name}" doesn't exist.`);
  }

  return (
    <StyledIconWrapper
      ref={ref}
      className={className}
      as={type || as}
      $icon={name}
      $enableHoverAnim={enableHoverAnim}
    >
      <SelectedIcon />
    </StyledIconWrapper>
  );
};
