import styled from 'styled-components';
import { easeOut, size } from 'polished';
import { zIndex } from '../../../../style/util/z-index';
import { device } from '../../../../style/util/media-query';

export const transitionDuration = 0.4;

export const StyledModalContainer = styled.div`
  ${size('100vh', '100vw')};
  ${zIndex('modal')};
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModalContent = styled.div<{ $mounted: boolean }>`
  ${size('100%')};
  position: relative;
  pointer-events: none;
  opacity: 0;
  transform: translateY(25rem);
  ${({ $mounted }) => $mounted && `opacity: 1; transform: translateY(0);`};
  transition: transform 0.4s ${easeOut('quad')}, opacity 0.4s;
  transition-duration: ${({ $mounted }) =>
    $mounted ? `${transitionDuration}s` : `${transitionDuration - 0.1}s`};

  > * {
    pointer-events: ${({ $mounted }) => $mounted && 'auto'};
  }

  @media ${device.tablet} {
    ${size('auto')};
  }
`;

export const StyledModalBackdrop = styled.div<{ $mounted: boolean }>`
  ${size('100%')};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.25);
  ${({ $mounted }) => $mounted && `opacity: 1`};
  ${({ $mounted }) => !$mounted && 'pointer-events: none;'};
  transition: opacity ${transitionDuration - 0.1}s ${({ $mounted }) => ($mounted ? '0s' : '0.1s')};
`;
