export const addClass = (
  element: HTMLElement | Element,
  className: string,
  add: boolean = true
): void => {
  if (add && className && !element.classList.contains(className)) {
    element.classList.add(className);
    return;
  }

  if (!add && element.classList.contains(className)) {
    element.classList.remove(className);
  }
};
