import styled from 'styled-components';
import { size } from 'polished';
import { Heading } from '../../general/Heading/Heading';
import { device, hover } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { Paragraph } from '../../general/Paragraph/Paragraph';

export const StyledContent = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 8.4rem 4.8rem;
  text-align: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 2rem 2rem 0 0;

  @media ${device.tablet} {
    position: relative;
    width: 62.5rem;
    padding: 16rem 13rem;
    border-radius: 2rem;
  }
`;

export const StyledEyebrow = styled(Paragraph)`
  margin-bottom: 1rem;

  @media ${device.tablet} {
    margin-bottom: 1.8rem;
  }
`;

export const StyledHeading = styled(Heading)`
  font-weight: 700;
`;

export const StyledDescription = styled(Paragraph)`
  margin-top: 2rem;

  @media ${device.tablet} {
    margin-top: 2.4rem;
  }
`;

export const StyledButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export const StyledButton = styled(Button)`
  position: relative;
  margin: 1.2rem 0;
  width: 100%;

  @media ${device.tablet} {
    width: 16.4rem;
    margin: 1.2rem;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const StyledButtonClose = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.ozarkNoir};
  display: none;

  @media ${hover} {
    &:hover {
      background-color: transparent;
    }
  }
  @media ${device.tablet} {
    display: inline-flex;
  }
`;

export const StyledButtonCloseMobile = styled.div`
  ${size('0.3rem', '7.2rem')};
  position: absolute;
  top: 1.5rem;
  left: calc(50% - 7.2rem * 0.5);
  border-radius: 0.2rem;
  background-color: ${({ theme }) => theme.color.ozarkNoir};

  @media ${device.tablet} {
    display: none;
  }
`;
