import React, { forwardRef, Ref } from 'react';
import type {
  ParagraphSize,
  ParagraphType,
  TextAlignType,
  TextTransformType
} from '../../../style/type/type-style';
import { StyledParagraph } from './Paragraph.styled';

export interface TextProps {
  textTransform?: TextTransformType;
  textAlign?: TextAlignType;
  color?: string;
  fontWeight?: string;
}

export interface ParagraphProps extends TextProps {
  type?: ParagraphType;
  size?: ParagraphSize;
}

export const Paragraph: React.FunctionComponent<ParagraphProps> = forwardRef(
  (
    { type = 'p', size = 'medium', textTransform, textAlign, color, ...props },
    ref: Ref<HTMLParagraphElement>
  ) => (
    <StyledParagraph
      ref={ref}
      as={type}
      $size={size}
      $textTransform={textTransform}
      $textAlign={textAlign}
      $color={color}
      {...props}
    />
  )
);
Paragraph.displayName = 'paragraph';
