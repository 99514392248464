import { Link } from 'react-router-dom';
import React from 'react';

export type AbstractButtonProps = React.ComponentProps<'a'> & React.ComponentProps<'button'>;

export const AbstractButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  AbstractButtonProps
>(({ children, ...props }, ref) => {
  let Component: 'a' | 'button' = 'button';

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const componentProps = { ...props } as any;

  // Internal element/props
  if (props.href && props.href.startsWith('/')) {
    delete componentProps.href;
    return (
      <Link {...componentProps} to={props.href} ref={ref}>
        {children}
      </Link>
    );
  }

  // External element/props
  if (props.href && !props.href.startsWith('/')) {
    Component = 'a';

    delete componentProps.to;
    componentProps.target = '_blank';
    componentProps.rel = 'noopener noreferrer';
  }
  // ToggleButton props
  else if (props.type == null) {
    componentProps.type = 'button';
  }

  return (
    <Component {...componentProps} ref={ref}>
      {children}
    </Component>
  );
});
