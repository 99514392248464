import React, { useEffect, useState } from 'react';
import {
  StyledSection,
  StyledSectionInner,
  StyledTitle,
  StyledTabsContainer,
  StyledTabBarContainer,
  StyledTabBar,
  StyledTabContent,
  StyledButtonWrapper,
  StyledButton
} from './TabsHorizontal.styled';
import { LineIcon } from '../../content/TabContent/TabsContent';
import { TempoCtaGroup, TempoStoryWithBubble } from '../../../data/type/content';

export interface TabsProps {
  title: string;
  storyGroup: Array<TempoStoryWithBubble>;
  ctaGroup?: Array<TempoCtaGroup>;
}

const lineIcons: Array<LineIcon> = ['lineArrow2', 'curlyLine7', 'curlyLine8'];

const TabsHorizontal: React.FC<TabsProps> = ({ title, storyGroup: list, ctaGroup }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState<TempoStoryWithBubble | null>(null);
  const [tabList, setTabList] = useState<Array<string | null>>([]);

  useEffect(() => {
    setCurrentTab(list[currentIndex]);
  }, [currentIndex, list]);

  useEffect(() => {
    setTabList(
      list.map(({ title: tabTitle }) => (typeof tabTitle === 'string' ? tabTitle : tabTitle.title))
    );
  }, [list]);

  return (
    <StyledSection>
      <StyledSectionInner>
        <StyledTitle type="h2">{title}</StyledTitle>
        <StyledTabsContainer>
          <StyledTabBarContainer>
            <StyledTabBar
              list={tabList}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </StyledTabBarContainer>
          {currentTab && (
            <StyledTabContent
              content={currentTab}
              reverse={currentIndex % 2 === 1}
              line={lineIcons[currentIndex]}
            />
          )}
        </StyledTabsContainer>
        {ctaGroup && ctaGroup.length > 0 && (
          <StyledButtonWrapper>
            <StyledButton href={ctaGroup[0].cta.clickThrough.value}>
              {ctaGroup[0].cta.linkText}
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledSectionInner>
    </StyledSection>
  );
};

export default TabsHorizontal;
