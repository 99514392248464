import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { easeOut, size } from 'polished';
import { Heading } from '../../general/Heading/Heading';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import Avatar from '../../general/Avatar/Avatar';
import { device } from '../../../style/util/media-query';
import { shapeWidth, shapeWidthMobile, StyledShapeSize } from '../../general/Shape/Shape.styled';
import { Icon } from '../../general/Icon/Icon';
import Shape from '../../general/Shape/Shape';
import { zIndex } from '../../../style/util/z-index';
import { Paragraph } from '../../general/Paragraph/Paragraph';
import { transition } from '../../../style/util/transition';
import { ClassList } from '../../../data/enum/ClassList';

export const spacerHeightVh = 150;

const headingTransitionDuration = 0.5;
const headingTransitionOutDuration = 0.3;

const contentTransition = ({
  y,
  inDelay
}: {
  y?: number;
  inDelay?: number;
}): FlattenSimpleInterpolation => css`
  ${transition({
    inDuration: headingTransitionDuration,
    outDuration: headingTransitionOutDuration,
    y,
    selector: `.content.${ClassList.Active}`,
    inDelay
  })};
`;

export const StyledSection = styled(StyledBaseSection)`
  padding: 3rem 0 10rem;
  background-color: ${({ theme }) => theme.color.greyLight};
  z-index: 0;
  overflow: visible;

  @media ${device.desktop} {
    padding-bottom: 16rem;
  }
`;

export const StyledSpacer = styled.div`
  height: ${spacerHeightVh}vh;
`;

export const StyledSectionWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: none;
`;

export const StyledEyebrow = styled(Paragraph)`
  ${contentTransition({})};
`;

export const StyledHeadingContainer = styled.div`
  position: relative;
  width: 100%;
`;

function headingStyle() {
  let styles = '';

  for (let index: number = 0; index < 3; index += 1) {
    styles += `
       &:nth-of-type(${index + 1}) {
        .content.active[data-active-index="${index}"] & {
          .text {
            opacity: 1;
            transform: none;
            transition-delay: ${headingTransitionOutDuration}s;
            transition-duration: ${headingTransitionDuration}s;

            &:last-of-type {
              transition-delay: ${headingTransitionOutDuration + 0.1}s;
            }
          }
        }
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const StyledHeading = styled(Heading)`
  ${headingStyle()};
  width: 100%;
  margin: 1rem 0;
  padding: 0 2rem;

  &:not(:first-of-type) {
    position: absolute;
    top: 0;
  }

  .text {
    display: block;
    opacity: 0;
    transform: translateY(50%);
    transition: opacity ${headingTransitionOutDuration}s ease-out,
      transform 0s ${easeOut('quad')} ${headingTransitionOutDuration}s;

    &:first-of-type {
      color: ${({ theme }) => theme.color.blue};
    }
  }

  @media ${device.desktop} {
    margin: 1.5rem 0;

    span {
      display: block;
    }
  }
`;

export const StyledAvatarContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem auto;
  ${contentTransition({ y: 3, inDelay: 0.6 })};

  .speech-bubble {
    position: relative;
    top: 0.65em;
    left: auto;
    background: linear-gradient(
      98deg,
      rgba(255, 255, 255, 0.8) -4%,
      rgba(251, 250, 248, 0.99) 25%,
      rgba(251, 250, 248, 0.95) 85%,
      rgba(255, 255, 255, 0.75) 90%
    );
  }

  @media ${device.desktop} {
    margin: 7.5rem auto;
  }
`;

export const StyledLottieContainer = styled.div`
  ${size('11vw', '100%')};
  position: absolute;
  left: 0;
  bottom: calc(-5.5vw + 3rem);
  overflow: hidden;

  > div {
    ${size('100%')};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    // overwrite height and transform from lottie js
    height: calc(100vw * 9 / 16) !important;
    transform: translateY(-50%) !important;
  }

  // the current loop wave is too much
  // shrink the height temporary to reduce the motion
  .loop {
    transform: scaleY(0.5) scaleX(1.1);
  }
`;

// todo remove StyledLine once lottie implementation is done
export const StyledLine = styled(Icon)`
  ${size('9rem', '100%')};
  position: absolute;
  color: ${({ theme }) => theme.color.blue};
  left: 0;
  bottom: -1.5rem;
  opacity: 0;
  overflow: hidden;

  .content.active[data-active-bubble='true'] & {
    opacity: 1;
    transition: opacity 0.75s 0.8s;
  }

  svg {
    ${size('9rem', '144rem')};
  }

  @media ${device.desktopL} {
    ${size('6.25vw', '100%')};
    bottom: -1vw;

    svg {
      ${size('100%')};
    }
  }
`;

export const StyledAnimContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 1rem;
`;

export const StyledShapeContainer = styled.div`
  position: relative;
  width: calc(${shapeWidthMobile} * 10);

  @media ${device.desktop} {
    width: calc(${shapeWidth} * 10);
  }

  @media (min-width: ${parseInt(shapeWidth, 10) * 100}px) {
    margin: 0 auto;
  }
`;

export const StyledShapeRow = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledShape = styled(Shape)<{ $marginLeft?: boolean; $marginLeftHalf?: boolean }>`
  ${({ $marginLeft }) => $marginLeft && `margin-left: ${shapeWidthMobile}`};
  ${({ $marginLeftHalf }) => $marginLeftHalf && `margin-left: calc(${shapeWidthMobile} * 0.5)`}};

  &:nth-of-type(3),
  &:nth-of-type(5),
  &:nth-of-type(7),
  &:nth-of-type(11),
  &:nth-of-type(12) {
    ${zIndex('content')};
  }

  @media ${device.desktop} {
    ${({ $marginLeft }) => $marginLeft && `margin-left: ${shapeWidth};`};
    ${({ $marginLeftHalf }) => $marginLeftHalf && `margin-left: calc(${shapeWidth} * 0.5);`};
  }
`;

export const StyledShapeSpacer = styled.div<{ $isHalf?: boolean }>`
  ${({ $isHalf }) => StyledShapeSize($isHalf)};
`;

export const StyledBag = styled(Icon)`
  ${size('55rem', '35rem')};
  ${zIndex('content')};
  position: relative;
  margin-top: 40vh;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  color: ${({ theme }) => theme.color.blue};

  @media ${device.tablet} {
    ${size('63.8rem', '40.6rem')};
  }

  @media ${device.desktop} {
    ${size('96.8rem', '61.6rem')};
    margin-top: 70vh;
  }
`;

export const StyledBagHandle = styled(StyledBag)`
  ${zIndex('background')};
  position: absolute;
  bottom: 0;
`;

export const StyledLogo = styled(Icon)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  left: calc(50% - 6rem);
  margin-top: -6rem;
  ${size('12rem')};

  svg {
    ${size('3rem')};
  }

  @media ${device.desktop} {
    ${size('20rem')};
    left: calc(50% - 10rem);
    margin-top: -10rem;

    svg {
      ${size('4rem')};
    }
  }
`;
