import type { WalmartTheme } from 'styled-components';
import './fonts.css';

export const theme: WalmartTheme = {
  font: {
    bogle: `"BogleWeb", sans-serif`
  },
  color: {
    white: '#FFF',
    black: '#000',
    ozarkNoir: '#041E42',
    ozarkNoir70: '#4f617a', // ozarkNoir opacity 0.7 over white
    ozarkNoir60: '#5E6E83',
    ozarkNoirHover: '#002D6B',
    blue: '#0071DC',
    blue60: '#66aaea', // blue opacity 0.6 over white
    blueLight: '#2E88DE',
    blueHover: '#005CB4',
    yellow: '#FFC220',
    grey: '#CCC',
    greyLight: '#FBFAF8'
  },
  topNavHeight: '10rem',
  topNavHeightMobile: '8rem',
  sectionInnerMaxWidth: '176rem',
  sitePaddingHorizontal: '8rem',
  sitePaddingHorizontalMobile: '2rem',
  gridSpacingMobile: '1.6rem',
  gridSpacing: '2.5rem',
  columnsTotal: 12,
  columnsTotalTablet: 8,
  columnsTotalMobile: 4
};
