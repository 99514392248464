import React, { forwardRef, Ref } from 'react';
import type { HeadingType } from '../../../style/type/type-style';
import type { TextProps } from '../Paragraph/Paragraph';
import { StyledHeading } from './Heading.styled';

export interface HeadingProps extends TextProps {
  type: HeadingType;
  as?: HeadingType;
  tag?: HeadingType;
  children?: React.ReactNode;
  className?: string;
}

export const Heading: React.FunctionComponent<HeadingProps> = forwardRef(
  (
    { type, as, tag, textAlign, textTransform, color, fontWeight, ...props },
    ref: Ref<HTMLHeadingElement>
  ) => {
    return (
      <StyledHeading
        ref={ref}
        as={as || tag || type}
        $type={type}
        $textTransform={textTransform}
        $textAlign={textAlign}
        $color={color}
        $fontWeight={fontWeight}
        {...props}
      />
    );
  }
);
Heading.displayName = 'heading';
