import { useCallback, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

/**
 * Custom hook to monitor `ONLY` `inView` state of your element.
 * Returns `setTriggerRef` and boolean of `isInView`
 *
 * Useful for play/pause looping video or animation, add/remove listeners etc
 * */

type InViewResponse = [(node?: Element | null) => void, boolean];

interface InViewOptions {
  trigger?: Element | string | null;
  initialInView?: boolean;
  start?: string;
  end?: string;
  forwardOnly?: boolean;
}

export function useInView({
  initialInView,
  start = 'top 100%-=100px', // when the top of the trigger element hits the bottom 100px of the viewport
  end = 'bottom 100px', // end when the bottom of the trigger element hits the top 100px of the viewport
  forwardOnly = false
}: InViewOptions = {}): InViewResponse {
  const [isInView, setIsInView] = useState<boolean>(!!initialInView);
  const triggerRef = useRef<Element | null>(null);
  const setTriggerRef = useCallback((node) => {
    // Save a reference to the node
    triggerRef.current = node;
  }, []);

  useEffect(() => {
    let scrollTrigger: gsap.plugins.ScrollTriggerInstance | null;

    setTimeout(() => {
      const trigger = triggerRef.current;
      if (trigger) {
        scrollTrigger = ScrollTrigger.create({
          trigger,
          start,
          end,
          onToggle: ({ isActive, progress }) => {
            setIsInView(isActive || (forwardOnly && progress > 0));
          }
        });
      }
    }, 100);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
        scrollTrigger = null;
      }
    };
  }, [start, end, forwardOnly]);

  return [setTriggerRef, isInView];
}
