import React, { SyntheticEvent } from 'react';
import { StyledTabBar, StyledTab, StyledTabButton } from './TabBar.styled';
import { TabsVariantType } from '../../../data/type/content';

interface TabBarProps {
  variant?: TabsVariantType;
  list: Array<string | null>;
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
  tabToScroll?: (index: number) => void;
  className?: string;
}

const TabBar: React.FC<TabBarProps> = ({
  variant = 'horizontal',
  list,
  currentIndex = -1,
  setCurrentIndex,
  tabToScroll,
  className
}) => {
  const handleClick = ({ currentTarget }: SyntheticEvent<HTMLElement>) => {
    const index = parseInt(currentTarget.dataset.tabIndex || '0', 10);
    if (tabToScroll) {
      tabToScroll(index);
    }

    if (setCurrentIndex) {
      setCurrentIndex(index);
    }
  };

  return (
    <StyledTabBar className={className} $variant={variant}>
      {list.map((label, index) => (
        <StyledTab key={label}>
          <StyledTabButton
            buttonTheme="tertiary"
            role="tab"
            onClick={handleClick}
            data-tab-index={index}
            className={index === currentIndex ? 'selected' : ''}
            key={label}
          >
            {label}
          </StyledTabButton>
        </StyledTab>
      ))}
    </StyledTabBar>
  );
};

export default TabBar;
