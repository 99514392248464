import React, { useEffect, useState } from 'react';
import {
  StyledShapeContainer,
  StyledSectionInner,
  StyledSection,
  StyledShapeGroup
} from './HeroSecondary.styled';
import { CopyContent, TempoImage } from '../../../data/type/content';
import VhTracker from '../../general/VhTracker/VhTracker';
import Shape from '../../general/Shape/Shape';
import BaseContent from '../../content/BaseContent/BaseContent';
import { ClassList } from '../../../data/enum/ClassList';

export interface HeroSecondaryProps extends CopyContent {
  image?: TempoImage;
}

const HeroSecondary: React.FC<HeroSecondaryProps> = ({
  eyebrow,
  title,
  description,
  ctaGroup,
  image = {
    src: ''
  }
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 20);
  }, []);
  return (
    <>
      <VhTracker />
      <StyledSection
        $backgroundImage={image.src}
        className={`${mounted ? ClassList.Mounted : ''} ${image.src ? 'hero-image' : ''}`}
      >
        {!image.src && (
          <StyledShapeContainer>
            <StyledShapeGroup>
              <Shape icon="shapeWatermelon" color="ozarkNoir" />
              <Shape icon="shapeMacaron" color="blue" />
            </StyledShapeGroup>
            <StyledShapeGroup>
              <Shape icon="shapeWatermelon" color="yellow" />
              <Shape icon="shapeDonut" color="ozarkNoir" />
              <Shape icon="shapeMacaron" color="yellow" />
              <Shape icon="shapeMacaron" color="ozarkNoir" />
            </StyledShapeGroup>
            <StyledShapeGroup>
              <Shape icon="shapePizza" color="white" />
              <Shape icon="shapeApple" color="yellow" />
              <Shape icon="shapePizza" color="blue" />
            </StyledShapeGroup>
            <StyledShapeGroup>
              <Shape icon="shapePizza" color="yellow" noStroke />
              <Shape icon="shapePizza" color="blue" noStroke />
            </StyledShapeGroup>
          </StyledShapeContainer>
        )}
        <StyledSectionInner>
          <BaseContent
            eyebrow={eyebrow}
            title={title}
            description={description}
            cta={ctaGroup ? ctaGroup[0].cta : undefined}
            isOverBackground={!!image.src}
            isHero
            isMobileOnly
          />
        </StyledSectionInner>
      </StyledSection>
    </>
  );
};

export default HeroSecondary;
