import React, { useEffect } from 'react';
import { CardContent } from '../../content/Card/Card';
import {
  StyledBackground,
  StyledCurlyVector,
  StyledCurlyVector2,
  StyledCurlyVectorMobile,
  StyledEyebrow,
  StyledTitleContainer,
  StyledLineArrow,
  StyledLineVector,
  StyledLineVectorMobile,
  StyledSection,
  StyledSectionInner,
  StyledCard,
  StyledShapeMacarronYellow,
  StyledShapeMacarronWhite,
  StyledShapePizza,
  StyledShapeWatermelonBottom,
  StyledShapeWatermelonTop,
  StyledLine
} from './Cards.styled';
import { VariantType } from '../../../data/type/content';
import { Heading } from '../../general/Heading/Heading';
import { useInView } from '../../../hooks/use-in-view';
import { ClassList } from '../../../data/enum/ClassList';

export interface CardsProps {
  variant?: VariantType | undefined;
  eyebrow?: string;
  title: string;
  cardGroup: Array<CardContent>;
}

const Card: React.FC<CardsProps> = ({ variant = 'primary', eyebrow, title, cardGroup }) => {
  const [containerRef, isInView] = useInView({ start: 'top 20px', end: 'bottom 20px' });

  useEffect(() => {
    const { classList } = document.body;
    if (isInView) {
      classList.add(ClassList.BlueBackground);
    } else {
      classList.remove(ClassList.BlueBackground);
    }
  }, [isInView]);

  return (
    <StyledSection ref={containerRef}>
      <StyledBackground>
        <StyledLineVectorMobile name="curvedLine0" />
        <StyledCurlyVectorMobile name="curlyLine3" />
        {variant === 'primary' && (
          <>
            {/* yellow lines */}
            <StyledLineArrow name="lineArrow0" />
            <StyledCurlyVector name="curlyLine0" />
            <StyledCurlyVector2 name="curlyLine1" />
            <StyledLineVector name="curvedLine1" />
            {/* shapes */}
            <StyledShapePizza icon="shapePizza" color="white" />
            <StyledShapeWatermelonTop icon="shapeWatermelon" color="ozarkNoir" />
            <StyledShapeWatermelonBottom icon="shapeWatermelon" color="white" />
            <StyledShapeMacarronYellow icon="shapeMacaron" color="yellow" />
            <StyledShapeMacarronWhite icon="shapeMacaron" color="white" />
          </>
        )}
        {variant === 'secondary' && <StyledLine name="curlyLineLong0" />}
      </StyledBackground>
      <StyledSectionInner $variant={variant}>
        <StyledTitleContainer $variant={variant}>
          {eyebrow && <StyledEyebrow size="eyebrow">{eyebrow}</StyledEyebrow>}
          <Heading type="h2">{title}</Heading>
        </StyledTitleContainer>
        {cardGroup.map((item) => (
          <StyledCard {...item} key={item.title} $variant={variant} />
        ))}
      </StyledSectionInner>
    </StyledSection>
  );
};

export default Card;
