import { ReactElement, Fragment } from 'react';
import Faq from '../block/Faq/Faq';
import HeroHome from '../block/HeroHome/HeroHome';
import Cards from '../block/Cards/Cards';
import KeyMessage from '../block/KeyMessage/KeyMessage';
import TabsHorizontal from '../block/TabsHorizontal/TabsHorizontal';
import useGlobal from '../../store';
import { useLoadContent } from '../../hooks/use-load-content';

export default function HomePage(): ReactElement {
  const [{ home }] = useGlobal();
  useLoadContent('home');

  if (!home) {
    return <></>;
  }

  return (
    <Fragment key="home">
      {home.hero && <HeroHome {...home.hero} />}
      {home.tabs && <TabsHorizontal {...home.tabs} />}
      {home.keyMessage && <KeyMessage {...home.keyMessage} />}
      {home.faq && <Faq {...home.faq} />}
      {home.cards && <Cards {...home.cards} />}
    </Fragment>
  );
}
