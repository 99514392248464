import React, { useEffect, useState } from 'react';
import {
  StyledDialogue,
  StyledSpeechBubbles,
  StyledSpeechBubble,
  StyledMiniAvatar
} from './Dialogue.styled';
import { TabsVariantType, TempoBubble } from '../../../data/type/content';

export interface SpeechBubble {
  avatar?: string;
  bubbles: Array<string>;
}

export interface DialogueProps {
  speechBubbles: Array<TempoBubble>;
  variant: TabsVariantType;
  enableBackdropFilter?: boolean;
  delay?: number;
  transitionOutDuration?: number;
  transitionOut?: boolean;
  className?: string;
}

const Dialogue: React.FC<DialogueProps> = ({
  speechBubbles,
  variant,
  enableBackdropFilter,
  delay = 0,
  transitionOutDuration = 0.5,
  transitionOut,
  className
}) => {
  const [mounted, setMounted] = useState(false);
  const [dialogues, setDialogues] = useState<Array<SpeechBubble>>([]);

  useEffect(() => {
    const data = speechBubbles
      .filter((item, index) => index < 2)
      .map(({ avatar, conversation }) => ({ avatar: avatar?.src, bubbles: [conversation] }));
    if (speechBubbles.length > 2) {
      data[1].bubbles.push(speechBubbles[2].conversation);
    }
    setDialogues([...data]);
  }, [speechBubbles]);

  useEffect(() => {
    if (transitionOut) {
      setMounted(false);
      return;
    }

    setTimeout(() => {
      setMounted(true);
    }, 10);
  }, [transitionOut]);

  return (
    <StyledDialogue className={className}>
      {dialogues &&
        dialogues.length > 0 &&
        dialogues.map(({ avatar, bubbles }, index) => (
          <StyledSpeechBubbles
            $variant={variant}
            $isUser={index === 0}
            key={`speech-bubble-${bubbles[index]}`}
          >
            <StyledMiniAvatar
              $avatarImage={avatar || `/images/walmart-logo.svg`}
              $transitionOut={!mounted}
              $transitionOutDuration={transitionOutDuration}
              $delay={delay + index * 0.55}
            />
            {bubbles.map((speech, bubbleIndex) => (
              <StyledSpeechBubble
                text={speech}
                avatarColor="blue"
                pointerDirection={index === 0 ? 'top-left' : 'top-right'}
                lineBreak
                enableBackdropFilter={enableBackdropFilter}
                key={speech}
                $transitionOut={!mounted}
                $transitionOutDuration={transitionOutDuration}
                $delay={delay + (index * 0.55 + bubbleIndex * 0.2 + 0.1)}
              />
            ))}
          </StyledSpeechBubbles>
        ))}
    </StyledDialogue>
  );
};

export default Dialogue;
