export const fluidSize = (
  property: string,
  minSize: number,
  maxSize: number,
  minViewport = 375,
  maxViewport = 1440,
  byWidth = true
): string => `
  ${property}: ${minSize}px;

  @media screen and (min-${byWidth ? 'width' : 'height'}: ${minViewport}px) {
    ${property}: calc(
      ${minSize}px + ${maxSize - minSize} * (100${byWidth ? 'vw' : 'vh'} - ${minViewport}px) /
        ${maxViewport - minViewport}
    );
  }

  @media screen and (min-${byWidth ? 'width' : 'height'}: ${maxViewport}px) {
    ${property}: ${maxSize}px;
  }
`;

export const fluidType = (
  minSize: number,
  maxSize: number,
  minViewport = 375,
  maxViewport = 1440,
  byWidth = true
): string => `
  ${fluidSize('font-size', minSize, maxSize, minViewport, maxViewport, byWidth)};
`;
