import { ReactComponent as logo } from '../assets/svg/logo.svg';
import { ReactComponent as walmartVoice } from '../assets/svg/walmart-voice.svg';
import { ReactComponent as close } from '../assets/svg/close.svg';
import { ReactComponent as plus } from '../assets/svg/plus.svg';
import { ReactComponent as arrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as minus } from '../assets/svg/minus.svg';
import { ReactComponent as arrowLeft } from '../assets/svg/arrow-left.svg';
import { ReactComponent as arrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as sound } from '../assets/svg/sound.svg';
import { ReactComponent as menu } from '../assets/svg/menu.svg';
import { ReactComponent as heroBgShape } from '../assets/svg/hero-bg-shape.svg';
import { ReactComponent as bananas } from '../assets/svg/bananas.svg';
import { ReactComponent as coffee } from '../assets/svg/coffee.svg';
import { ReactComponent as shapeApple } from '../assets/svg/shape-apple.svg';
import { ReactComponent as shapePizza } from '../assets/svg/shape-pizza.svg';
import { ReactComponent as shapeWatermelon } from '../assets/svg/shape-watermelon.svg';
import { ReactComponent as shapeMacaron } from '../assets/svg/shape-macaron.svg';
import { ReactComponent as shapeDonut } from '../assets/svg/shape-donut.svg';
import { ReactComponent as curvedLine0 } from '../assets/svg/curved-line-0.svg';
import { ReactComponent as curvedLine1 } from '../assets/svg/curved-line-1.svg';
import { ReactComponent as curvedLine2 } from '../assets/svg/curved-line-2.svg';
import { ReactComponent as curvedLine3 } from '../assets/svg/curved-line-3.svg';
import { ReactComponent as curlyLine0 } from '../assets/svg/curly-line-0.svg';
import { ReactComponent as curlyLine1 } from '../assets/svg/curly-line-1.svg';
import { ReactComponent as curlyLine2 } from '../assets/svg/curly-line-2.svg';
import { ReactComponent as curlyLine3 } from '../assets/svg/curly-line-3.svg';
import { ReactComponent as curlyLine4 } from '../assets/svg/curly-line-4.svg';
import { ReactComponent as curlyLine5 } from '../assets/svg/curly-line-5.svg';
import { ReactComponent as curlyLine6 } from '../assets/svg/curly-line-6.svg';
import { ReactComponent as curlyLine7 } from '../assets/svg/curly-line-7.svg';
import { ReactComponent as curlyLine8 } from '../assets/svg/curly-line-8.svg';
import { ReactComponent as curlyLine9 } from '../assets/svg/curly-line-9.svg';
import { ReactComponent as curlyLineLong0 } from '../assets/svg/curly-line-long-0.svg';
import { ReactComponent as lineArrow0 } from '../assets/svg/line-arrow-0.svg';
import { ReactComponent as lineArrow1 } from '../assets/svg/line-arrow-1.svg';
import { ReactComponent as lineArrow2 } from '../assets/svg/line-arrow-2.svg';
import { ReactComponent as bag } from '../assets/svg/bag.svg';
import { ReactComponent as bagHandle } from '../assets/svg/bag-handle-back.svg';
import { ReactComponent as instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as twitter } from '../assets/svg/twitter.svg';
import { ReactComponent as facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as lineVectorDevices } from '../assets/svg/line-vector-devices.svg';
import { ReactComponent as curlyVector3 } from '../assets/svg/curly-vector-3.svg';
import { ReactComponent as curlyVector4 } from '../assets/svg/curly-vector-4.svg';
import { ReactComponent as googleAssistant } from '../assets/svg/google-assistant.svg';
import { ReactComponent as siri } from '../assets/svg/siri.svg';
import { ReactComponent as lineVectorSetUp } from '../assets/svg/line-vector-setup.svg';
import { ReactComponent as rotatePhone } from '../assets/svg/rotate-phone.svg';

export const icons = {
  logo,
  walmartVoice,
  close,
  plus,
  minus,
  arrowRight,
  arrowLeft,
  arrowDown,
  sound,
  menu,
  heroBgShape,
  bananas,
  coffee,
  shapeApple,
  shapePizza,
  shapeWatermelon,
  shapeMacaron,
  shapeDonut,
  curlyVector3,
  curlyVector4,
  curvedLine0,
  curvedLine1,
  curvedLine2,
  curvedLine3,
  curlyLine0,
  curlyLine1,
  curlyLine2,
  curlyLine3,
  curlyLine4,
  curlyLine5,
  curlyLine6,
  curlyLine7,
  curlyLine8,
  curlyLine9,
  curlyLineLong0,
  lineArrow0,
  lineArrow1,
  lineArrow2,
  bag,
  bagHandle,
  instagram,
  twitter,
  facebook,
  lineVectorDevices,
  googleAssistant,
  siri,
  lineVectorSetUp,
  rotatePhone
};
