import styled, { css } from 'styled-components';
import { size } from 'polished';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { device, sizes } from '../../../style/util/media-query';
import Avatar from '../../general/Avatar/Avatar';
import { fluidSize, fluidType } from '../../../style/util/fluid-size';
import { Icon } from '../../general/Icon/Icon';
import { zIndex } from '../../../style/util/z-index';
import {
  curvedHeight,
  curvedHeightMobile,
  fullHeightWithCurved
} from '../../../style/util/full-height';
import { backgroundBlueHorizontalGradient } from '../../../style/util/background-gradient';
import BaseContent from '../../content/BaseContent/BaseContent';
import { transitionFrom, transitionTo } from '../../../style/util/transition';
import { ClassList } from '../../../data/enum/ClassList';

const buttonWrapperHeight = '8.4rem';

export const StyledSection = styled(StyledBaseSection)`
  ${zIndex('content')};
  ${fullHeightWithCurved};
  color: ${({ theme }) => theme.color.white};

  &::before {
    ${backgroundBlueHorizontalGradient};
  }
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (max-width: ${sizes.desktop - 1}px) {
    padding: 0 0 ${buttonWrapperHeight};
  }

  @media ${device.desktop} {
    align-items: center;
  }
`;

export const StyledHeroContent = styled(BaseContent)`
  margin-top: 3rem;

  @media ${device.tablet} {
    .title {
      width: 11em;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  ${fluidSize('bottom', 80, 124, 550, 800, false)};
  ${size('4.4rem')};
  position: absolute;
  left: calc(50% - 2.2rem);
  ${transitionFrom({ y: -1, ease: 'easeOutBack', delay: 1.8 })};

  .${ClassList.Mounted} & {
    ${transitionTo({ duration: 0.4 })};
  }

  @media ${device.desktop} {
    bottom: 3.5rem;
  }
`;

export const StyledAvatarContainer = styled.div`
  ${size(`calc(100% - ${curvedHeightMobile} - ${buttonWrapperHeight} * 0.75)`, '100%')};
  max-width: 40rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    max-width: 144rem;
  }

  @media ${device.desktop} {
    height: calc(100% - ${curvedHeight});
  }
`;

function avatarTransitionDelay() {
  let styles = '';
  const length = 4;
  for (let index: number = 0; index < length; index += 1) {
    styles += `
       &:nth-of-type(${index + 1}) {
          transition-delay: ${index * 0.24 + (length - index) * 0.1 + 0.45}s;
       };
     `;
  }
  return css`
    ${styles}
  `;
}

export const StyledAvatar = styled(Avatar)<{ $isActive: boolean }>`
  position: absolute;
  ${zIndex('content')};

  ${transitionFrom({ y: 3, ease: 'easeOutBack' })};
  ${avatarTransitionDelay()};

  .${ClassList.Mounted} & {
    ${transitionTo({ duration: 0.6 })};
  }

  .avatar-image {
    filter: blur(0.5rem);
    ${({ $isActive }) => $isActive && `filter: none;`};
    transition: filter 0.3s linear;
  }

  &:nth-of-type(1) {
    ${fluidType(90, 120, 375, 1440)};
    top: 28.5%;
    left: 7%;
  }

  &:nth-of-type(2) {
    ${fluidType(106, 164, 375, 1440)};
    top: 27%;
    left: 92.5%;
  }

  &:nth-of-type(3) {
    ${fluidType(110, 200, 375, 1440)};
    top: 80%;
    left: 7%;
  }

  &:nth-of-type(4) {
    ${fluidType(74, 142, 375, 1440)};
    top: 73.8%;
    left: 96%;
  }

  @media ${device.tablet} {
    &:nth-of-type(1) {
      top: 22.5%;
      left: 25.5%;
    }

    &:nth-of-type(2) {
      top: 22.3%;
      left: 72.8%;
    }

    &:nth-of-type(3) {
      top: 68%;
      left: 7.5%;
    }

    &:nth-of-type(4) {
      top: 80%;
      left: 82.4%;
    }
  }
`;

export const StyledAvatarBlur = styled.figure`
  ${fluidType(90, 120, 375, 1440)};
  ${size('1em')};
  position: absolute;
  margin-top: -0.5em;
  margin-left: -0.5em;
  ${transitionFrom({ delay: 1.5, duration: 1.5 })};

  .${ClassList.Mounted} & {
    ${transitionTo()};
  }

  img {
    ${size('100%')};
    position: relative;
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    display: none;
  }

  &:nth-of-type(3) {
    top: 90%;
    left: 80%;
  }

  @media ${device.tablet} {
    &:nth-of-type(1) {
      display: block;
      top: 28.5%;
      left: 1%;
    }

    &:nth-of-type(2) {
      ${size('1.1em')};
      display: block;
      top: 45.5%;
      left: 98%;
    }

    &:nth-of-type(3) {
      ${size('1.3em')};
      top: 86%;
      left: 39%;
    }
  }
`;
