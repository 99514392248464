import globalHook from 'use-global-hook';
import React from 'react';
import app, { AppState } from './module/app';

// Defining your own state and associated actions is required
export type StoreState = AppState;

// Associated actions are what's expected to be returned from globalHook
export type StoreActions = {
  fetchContent: (pageName: string) => Promise<{ success: boolean; signal: AbortSignal }>;
};

const initialState = {
  ...app.initialState
};

const actions = {
  ...app.actions
};

const useGlobal = globalHook<StoreState, StoreActions>(React, initialState, actions);

export default useGlobal;
