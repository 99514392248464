import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {
  StyledNavigation,
  StyledNavigationWrapper,
  StyledLogo,
  StyledLogoIcon,
  StyledNavigationMenus,
  StyledNavigationMenu,
  StyledMenuButton,
  StyledStartedButton,
  StyledToggleButton,
  StyledMenusBackground
} from './Navigation.styled';
import { useIsMobileSize } from '../../../hooks/use-is-mobile-size';
import { theme } from '../../../style/theme';
import Shape from '../../general/Shape/Shape';
import { ClassList } from '../../../data/enum/ClassList';
import { ModalContext } from '../../../context/ModalContext';
import useGlobal, { StoreState } from '../../../store';
import { toCamel } from '../../../util/string';
import { Page } from '../../../data/enum/tempo';
import { TempoCta } from '../../../data/type/content';

export interface NavigationGroup {
  links: Array<{ link: TempoCta }>;
  ctas: Array<{ link: TempoCta }>;
}

export interface NavigationProps {
  className?: string;
}

export type NavThemeColor = 'blue' | 'white';

const Navigation: React.FC<NavigationProps> = ({ className = '' }) => {
  const { pathname } = useLocation();
  const { openModal } = useContext(ModalContext);
  const [, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentColorTheme, setCurrentColorTheme] = useState<NavThemeColor>('white');
  const [isHomeHeroInView, setIsHomeHeroInView] = useState<boolean>(true);
  const navRef = useRef<HTMLElement>(null);
  const isMobileSize = useIsMobileSize();
  const page = (toCamel(pathname.split('/')[1]) || 'home') as Page;
  const [navigation] = useGlobal<NavigationGroup | null | undefined, undefined>(
    (state: StoreState) => state[page]?.navigation,
    () => undefined
  );

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (!isMobileSize) {
      setIsOpen(false);
    }
  }, [isMobileSize]);

  useEffect(() => {
    let lastScroll = 0;
    let homeHero: HTMLElement | null;
    const minScrollY = parseInt(theme.topNavHeight, 10) * 10;
    setCurrentColorTheme(pathname === '/faq' ? 'blue' : 'white');
    document.body.classList.remove(ClassList.ScrollDown);

    const handleScroll = () => {
      const { classList } = document.body;

      if (classList.contains(ClassList.ForceToScroll)) {
        return;
      }

      if (!homeHero) {
        homeHero = document.body.querySelector('.hero-home');
      }

      const scrollY = Math.max(window.scrollY, 0);
      const heroHeight = homeHero?.offsetHeight || 0;

      if (
        scrollY > minScrollY &&
        scrollY > lastScroll &&
        !classList.contains(ClassList.ScrollDown)
      ) {
        classList.add(ClassList.ScrollDown);
      } else if (scrollY < lastScroll && classList.contains(ClassList.ScrollDown)) {
        classList.remove(ClassList.ScrollDown);
      }

      if (pathname !== '/faq') {
        if (scrollY > minScrollY) {
          setCurrentColorTheme('blue');
        } else if (scrollY <= minScrollY) {
          setCurrentColorTheme('white');
        }
      }

      if (heroHeight > 0 && pathname === '/') {
        setIsHomeHeroInView(scrollY < heroHeight * 0.8);
      }

      lastScroll = scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [pathname]);

  // BodyScrollLocks
  useEffect(() => {
    if (!isOpen || !navRef.current) {
      return undefined;
    }
    const targetElement = navRef.current;
    disableBodyScroll(targetElement);

    return () => {
      if (targetElement) {
        enableBodyScroll(targetElement);
      }
    };
  }, [isOpen]);

  const handleClick = () => {
    if (isMobileSize) {
      setIsOpen(false);
    }
  };

  const handleStartedClick = () => {
    openModal();
  };

  const handleMobileMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledNavigation className={className} $isOpen={isOpen} ref={navRef}>
      <StyledNavigationWrapper $color={currentColorTheme}>
        <StyledLogo to="/" onClick={handleClick} data-nav-id="home">
          <StyledLogoIcon name="walmartVoice" $isOpen={isOpen} enableHoverAnim={false} />
        </StyledLogo>
        <StyledNavigationMenus $isOpen={isOpen}>
          {isMobileSize && (
            <StyledMenusBackground>
              <Shape icon="shapeMacaron" color="ozarkNoir" />
              <Shape icon="shapeApple" color="yellow" />
              <Shape icon="shapePizza" color="ozarkNoir" />
            </StyledMenusBackground>
          )}
          {navigation &&
            navigation.links &&
            navigation.links.map(({ link: { linkText, clickThrough } }) => (
              <StyledNavigationMenu key={linkText}>
                <StyledMenuButton
                  buttonTheme="tertiary"
                  onClick={handleClick}
                  href={clickThrough.value}
                  $isSelected={pathname === clickThrough.value}
                  $color={currentColorTheme}
                  data-nav-id={linkText}
                >
                  {linkText}
                </StyledMenuButton>
              </StyledNavigationMenu>
            ))}
        </StyledNavigationMenus>
        {isMobileSize && (
          <StyledToggleButton icon="menu" $isOpen={isOpen} onClick={handleMobileMenuClick} />
        )}
      </StyledNavigationWrapper>
      {!isMobileSize &&
        pathname === '/' &&
        navigation &&
        navigation.ctas &&
        navigation.ctas.length > 0 && (
          <StyledStartedButton
            buttonColor={currentColorTheme === 'white' ? 'dark' : 'default'}
            onClick={handleStartedClick}
            className={!isHomeHeroInView ? 'is-sticky' : ''}
          >
            {navigation.ctas[0].link.linkText}
          </StyledStartedButton>
        )}
    </StyledNavigation>
  );
};

export default Navigation;
