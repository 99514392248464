import type React from 'react';
import { useEffect, useRef } from 'react';
import { StyledVhTracker } from './VhTracker.styled';

const VhTracker: React.FC = () => {
  const vhRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!vhRef.current) {
      return;
    }

    // Get the browser's interface height on mobile
    const screenHeight = vhRef.current.offsetHeight;
    const browserInterfaceHeight = screenHeight - window.innerHeight;
    document.documentElement.style.setProperty('--vh-offset', `${browserInterfaceHeight}px`);
  }, []);

  return <StyledVhTracker ref={vhRef} />;
};

export default VhTracker;
