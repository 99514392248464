import styled from 'styled-components';
import { size } from 'polished';
import { device } from '../../../style/util/media-query';
import { Heading } from '../../general/Heading/Heading';
import { pseudo } from '../../../style/util/pseudo';
import { Paragraph } from '../../general/Paragraph/Paragraph';
import { StyledSideImage } from '../../block/Stepper/Stepper.styled';

export const StyledSteps = styled.li`
  text-align: center;

  @media ${device.desktop} {
    text-align: left;
  }
`;

export const StyledStepNumber = styled(Heading)`
  color: ${({ theme }) => theme.color.blue};
  flex-shrink: 0;

  @media ${device.desktop} {
    padding-right: 1rem;
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.blue};
  font-weight: bold;
`;

export const StyledDescription = styled(Paragraph)`
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.color.ozarkNoir60};

  @media ${device.desktopM} {
    padding-right: 20%;
    margin-bottom: 0;
  }
`;

export const StyledImageWrapper = styled(StyledSideImage)`
  position: relative;
  display: block;
  margin: auto;
  width: 20rem;

  @media ${device.desktop} {
    display: none;
  }
`;

export const StyledStepHeader = styled.div`
  margin-top: 5rem;
  @media ${device.desktop} {
    display: flex;
    justify-content: flex-start;
  }
`;

export const StyledLine = styled.div`
  display: none;

  @media ${device.desktop} {
    display: block;
    position: relative;
    width: 100%;
    margin: 3rem 0;
    border-top: 0.2rem solid ${({ theme }) => theme.color.blue};

    &:before {
      ${pseudo()};
      ${size('0.8rem')};
      top: -0.5rem;
      left: 0;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.blue};
    }
  }
`;
