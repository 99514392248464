import { device } from '../util/media-query';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
export type ParagraphType = 'p' | 'span' | 'label';
export type ParagraphSize = 'medium' | 'small' | 'eyebrow';
export type TextTransformType = 'none' | 'lowercase' | 'uppercase' | 'capitalize';
export type TextAlignType = 'center' | 'left' | 'right' | 'justify';

export const typeStyle: Record<HeadingType | ParagraphSize, string> = {
  h1: `
		font-size: 4.4rem;
		line-height: 1.11;
		font-weight: 700;

    @media ${device.tablet} {
		  font-size: 6rem;
    }

		@media ${device.desktop} {
		  font-size: 7.2rem;
    }
  `,
  h2: `
		font-size: 3.2rem;
		line-height: 1.16;
    font-weight: 700;

		@media ${device.desktop} {
		  font-size: 5rem;
    }
  `,
  h3: `
		font-size: 2.4rem;
		line-height: 1.25;

		@media ${device.desktop} {
		  font-size: 3.2rem;
    }
  `,
  h4: `
		font-size: 2rem;
		line-height: 1.33;

		@media ${device.desktop} {
		  font-size: 2.4rem;
    }
  `,
  h5: `
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;
  `,
  medium: `
    font-size: 1.6rem;
    line-height: 1.4;

    @media ${device.desktop} {
		  font-size: 2rem;
    }
  `,
  small: `
    font-size: 1.6rem;
    line-height: 1.5;
  `,
  eyebrow: `
    font-size: 1.6rem;
    line-height: 1.57;
    font-weight: 700;
    letter-spacing: 0.05em;
  `
};
