import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import {
  StyledSection,
  StyledSectionWrapper,
  StyledSectionInner,
  StyledHeadingContainer,
  StyledHeading,
  StyledEyebrow,
  StyledAvatarContainer,
  StyledAvatar,
  StyledLottieContainer,
  StyledAnimContainer,
  StyledShapeContainer,
  StyledShapeRow,
  StyledShape,
  StyledBag,
  StyledBagHandle,
  StyledSpacer
} from './KeyMessage.styled';
import { initScrollTrigger } from './animation';
import { addLottieAnimation, LottieAnimItems } from './animation-lottie';
import { TempoStoryWithBubble } from '../../../data/type/content';

export interface KeyMessageProps {
  eyebrow?: string;
  storyGroup: Array<TempoStoryWithBubble>;
}

const KeyMessage: React.FC<KeyMessageProps> = ({ eyebrow, storyGroup }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [messages, setMessages] = useState<Array<Array<string>>>([]);
  const sectionRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<HTMLDivElement>(null);
  const lottieIntroRef = useRef<HTMLDivElement>(null);
  const lottieIdleRef = useRef<HTMLDivElement>(null);
  const lottieLoopRef = useRef<HTMLDivElement>(null);
  const lottieAnimRef = useRef<LottieAnimItems | null>(null);
  const lottieAnimIndexRef = useRef<number>(0);

  useEffect(() => {
    const data: Array<Array<string>> = storyGroup.map(({ title, subtitle }) => [
      typeof title === 'string' ? title : title.title || '',
      subtitle || ''
    ]);
    setMessages([...data]);
  }, [storyGroup]);

  useEffect(() => {
    let destroy: () => void;
    // Scroll Trigger
    setTimeout(() => {
      if (sectionRef.current && animRef.current) {
        destroy = initScrollTrigger(
          sectionRef.current,
          animRef.current,
          setCurrentMessageIndex
        ).destroy;
      }
    }, 200);

    return () => {
      if (destroy) {
        destroy();
      }
    };
  }, []);

  // Add Lottie
  useEffect(() => {
    if (!lottieIntroRef.current || !lottieIdleRef.current || !lottieLoopRef.current) {
      return undefined;
    }
    const { destroy, animItems } = addLottieAnimation(
      lottieIntroRef.current,
      lottieIdleRef.current,
      lottieLoopRef.current
    );
    lottieAnimRef.current = animItems;

    return () => {
      if (destroy) {
        destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (lottieAnimRef.current) {
      const { idleAnim, introAnim } = lottieAnimRef.current;
      const pauseAll = () => {
        idleAnim.pause();
        introAnim.pause();

        gsap.set([lottieIntroRef.current, lottieIdleRef.current, lottieLoopRef.current], {
          display: 'none'
        });
      };

      pauseAll();

      switch (currentMessageIndex) {
        case 0:
          // Reverse intro anim if scrolling back
          break;

        case 1:
          if (lottieAnimIndexRef.current < 1) {
            introAnim.goToAndPlay(0, true);
            gsap.set(lottieIntroRef.current, { display: 'block' });
          } else {
            idleAnim.goToAndPlay(0, true);
            gsap.set(lottieIdleRef.current, { display: 'block' });
          }
          break;

        case 2:
        case 3:
          idleAnim.goToAndPlay(0, true);
          gsap.set(lottieIdleRef.current, { display: 'block' });
          break;

        default:
          break;
      }

      lottieAnimIndexRef.current = currentMessageIndex;
    }
  }, [currentMessageIndex]);

  return (
    <StyledSection ref={sectionRef}>
      <StyledSectionWrapper>
        <StyledSectionInner className="content" $padding="8.4rem 0">
          {eyebrow && <StyledEyebrow size="eyebrow">{eyebrow}</StyledEyebrow>}
          <StyledHeadingContainer className="messages">
            {messages.map((message) => (
              <StyledHeading type="h2" key={`key message ${message[0]}`}>
                {message.map((text) => (
                  <span className="text" key={text}>
                    {text}
                  </span>
                ))}
              </StyledHeading>
            ))}
          </StyledHeadingContainer>
          <StyledAvatarContainer>
            <StyledLottieContainer>
              <div className="intro" ref={lottieIntroRef} />
              <div className="idle" ref={lottieIdleRef} />
              <div className="loop" ref={lottieLoopRef} />
            </StyledLottieContainer>
            <StyledAvatar
              image={storyGroup[2].bubbleGroup[0].avatar.src}
              speech={storyGroup[2].bubbleGroup[0].conversation}
              isActive
              activeSelector=".content.active[data-active-bubble='true']"
              color="blue"
              soundBarLoopCount="infinite"
              hasTextAnim
              playPulse={currentMessageIndex === 2}
              enableBackdropFilter={false}
              textAnimDelay={0.6}
            />
          </StyledAvatarContainer>
        </StyledSectionInner>
        <StyledAnimContainer ref={animRef}>
          <StyledBagHandle name="bagHandle" />
          <StyledShapeContainer>
            {/* first row */}
            <StyledShapeRow className="row">
              <StyledShape icon="shapeWatermelon" color="ozarkNoir" />
              <StyledShape icon="shapePizza" noStroke $marginLeft />
              <StyledShape icon="shapeDonut" color="ozarkNoir" />
              <StyledShape icon="shapeMacaron" color="yellow" />
              <StyledShape icon="shapeMacaron" color="yellow" />
              <StyledShape icon="shapeWatermelon" noStroke rotation="180deg" $marginLeft />
              <StyledShape icon="shapeApple" color="yellow" />
              <StyledShape icon="shapePizza" noStroke />
              <StyledShape icon="shapePizza" noStroke />
            </StyledShapeRow>
            {/* second row */}
            <StyledShapeRow className="row">
              <StyledShape icon="shapeMacaron" color="yellow" noStroke />
              <StyledShape icon="shapePizza" />
              <StyledShape icon="shapeApple" color="yellow" />
              <StyledShape icon="shapePizza" $marginLeftHalf />
              <StyledShape icon="shapeWatermelon" color="yellow" $marginLeft />
              <StyledShape icon="shapeMacaron" color="ozarkNoir" $marginLeft />
              <StyledShape icon="shapeMacaron" color="yellow" />
              <StyledShape icon="shapeMacaron" color="ozarkNoir" $marginLeft />
              <StyledShape icon="shapeMacaron" color="yellow" />
            </StyledShapeRow>
          </StyledShapeContainer>
          <StyledBag name="bag" />
        </StyledAnimContainer>
      </StyledSectionWrapper>
      <StyledSpacer />
    </StyledSection>
  );
};

export default KeyMessage;
