import type React from 'react';
import { StyledIcon } from './ShapeLine.styled';
import { icons } from '../../../data/icons';

export type ShapeColor = 'blue' | 'yellow';

export interface ShapeProps {
  icon: keyof typeof icons;
  color?: ShapeColor;
  className?: string;
}

const ShapeLine: React.FC<ShapeProps> = ({ icon, color = 'yellow', className }) => {
  return <StyledIcon className={`${className} ${icon} shape-line`} name={icon} $color={color} />;
};

export default ShapeLine;
