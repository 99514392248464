import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { device, hover, sizes } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { TabsVariantType } from '../../../data/type/content';

export const StyledTabButton = styled(Button)`
  color: ${({ theme }) => theme.color.blue60};

  &.selected {
    color: ${({ theme }) => theme.color.blue};
    font-weight: 700;

    > span {
      &::before {
        border-bottom-width: 0.2rem;
      }
    }
  }

  @media ${hover} {
    &:hover {
      font-weight: 700;
      color: ${({ theme }) => theme.color.blue};
    }
  }

  @media ${device.desktop} {
    height: auto;
    margin: 1.8rem 0;
  }
`;

export const StyledTab = styled.li``;

const vertical = css`
  flex-direction: column;

  @media (max-width: ${sizes.desktop - 1}px) {
    display: none;
  }
`;
const horizontal = css`
  justify-content: center;

  ${StyledTab} {
    margin: 0 2.5rem;
  }

  @media ${device.tablet} {
    ${StyledTab} {
      margin: 0 4rem;
    }
  }
`;

const tabBarMap: Record<TabsVariantType, FlattenSimpleInterpolation> = {
  vertical,
  horizontal
};

export const StyledTabBar = styled.ul<{ $variant: TabsVariantType }>`
  ${({ $variant }) => tabBarMap[$variant]};
  display: flex;
  color: ${({ theme }) => theme.color.blue};

  @media ${device.desktop} {
    justify-content: center;
  }
`;
