import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TempoImage, TempoCta } from '../../../data/type/content';
import {
  StyledButton,
  StyledIconImage,
  StyledCard,
  StyledCardWrapper,
  StyledHeading
} from './Card.styled';

export interface CardContent {
  title: string;
  cta: TempoCta;
  image: TempoImage;
  className?: string;
}

const Card: React.FC<CardContent> = ({ title, cta, image, className }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const {
      clickThrough: { value: href }
    } = cta;
    if (href && href.startsWith('/')) {
      navigate(href);
    } else if (href && !href.startsWith('/')) {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StyledCard className={`${className} button`} onClick={handleClick}>
      <StyledCardWrapper>
        <StyledIconImage src={image.src} alt={image.alt || image.title} />
        <StyledHeading type="h3">{title}</StyledHeading>
        <StyledButton buttonTheme="tertiary" icon="arrowRight">
          {cta.linkText}
        </StyledButton>
      </StyledCardWrapper>
    </StyledCard>
  );
};

export default Card;
