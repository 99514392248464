import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
import type { ShapeColor } from './ShapeLine';
import { aspectRatio } from '../../../style/util/aspect-ratio';

export const StyledIcon = styled(Icon)<{
  $color: ShapeColor;
}>`
  position: absolute;
  color: ${({ theme, $color }) => theme.color[$color]};
  height: auto;

  svg {
    position: absolute;
    top: 0;
  }

  &.curlyLine1 {
    ${aspectRatio(263, 58, false)};
    width: 26.3rem;
  }

  &.curlyLine7 {
    ${aspectRatio(335, 130, false)};
    width: 33.5rem;
  }

  &.curlyLine9 {
    ${aspectRatio(345, 112, false)};
    width: 34.5rem;
  }

  &.curlyLine8 {
    ${aspectRatio(493, 185, false)};
    width: 49.3rem;
  }

  &.curvedLine3 {
    ${aspectRatio(288, 78, false)};
    width: 28.8rem;
  }

  &.lineArrow2 {
    ${aspectRatio(230, 120, false)};
    width: 23rem;
  }
`;
