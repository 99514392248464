import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import type { TempoFaqGroup } from '../../../data/type/content';
import {
  StyledSection,
  StyledSectionInner,
  StyledSidebar,
  StyledSidebarWrapper,
  StyledFaqAnchors,
  StyledAccordionWrapper
} from './Faq.styled';
import { Button } from '../../general/buttons/Button/Button';
import { Heading } from '../../general/Heading/Heading';
import { useIsMobileSize } from '../../../hooks/use-is-mobile-size';
import { addClass } from '../../../util/element';
import Accordion from '../../content/accordion/Accordion/Accordion';
import { ClassList } from '../../../data/enum/ClassList';

export interface FaqProps {
  title?: string;
  faqGroup?: Array<TempoFaqGroup>;
}

const Faq: React.FC<FaqProps> = ({ title, faqGroup: list }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarWrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isMobileSize = useIsMobileSize();

  useEffect(() => {
    const sidebarElement = sidebarRef.current;
    const observerElement = isMobileSize ? sidebarRef.current : sidebarWrapperRef.current;
    let observer: IntersectionObserver;

    if (observerElement && sidebarElement) {
      observer = new IntersectionObserver(
        ([{ intersectionRatio }]) => {
          const isPinned = isMobileSize
            ? intersectionRatio < 1 && observerElement.getBoundingClientRect().top < 0
            : intersectionRatio === 1;
          sidebarElement.classList.toggle('is-pinned', isPinned);
        },
        { threshold: [1] }
      );
      observer.observe(observerElement);
    }

    return () => {
      if (observerElement) {
        observer.unobserve(observerElement);
        observerElement.classList.remove('is-pinned');
      }
    };
  }, [isMobileSize]);

  const onInViewChange = (inViewIndex: number, inView: boolean): void => {
    if (sidebarWrapperRef.current) {
      const { innerHeight } = window;
      sidebarWrapperRef.current.querySelectorAll('button').forEach((button, index) => {
        const { top } = button.getBoundingClientRect();
        const visible = inView
          ? index === inViewIndex
          : index === inViewIndex - 1 && top < innerHeight;
        addClass(button, 'selected', visible);
      });
    }
  };

  const faqToScroll = (index: number) => {
    const element = contentRef.current?.querySelectorAll(`.accordion`)[index];
    if (element) {
      // place below the mobile sticky header
      const offsetY = window.innerWidth < 1024 ? -80 : 0;
      const { top } = element.getBoundingClientRect();
      const scrollTo = window.scrollY + top + offsetY;

      // Do not show the top navigation when force to scroll up
      if (top + offsetY < 0) {
        addClass(document.body, ClassList.ScrollDown);
        addClass(document.body, ClassList.ForceToScroll);
      }
      // Scroll window
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo,
        duration: 0.6,
        onComplete: () => {
          gsap.delayedCall(0.1, () => {
            addClass(document.body, ClassList.ForceToScroll, false);
          });
        }
      });
    }
  };

  if (!list) {
    return <></>;
  }

  return (
    <StyledSection $hasMultipleGroup={list.length > 1}>
      <StyledSectionInner>
        <StyledSidebar ref={sidebarRef} $hasMultipleGroup={list.length > 1}>
          <StyledSidebarWrapper ref={sidebarWrapperRef} $hasMultipleGroup={list.length > 1}>
            <Heading type="h2">{title || `FAQ`}</Heading>
            {list && list.length === 1 && list[0].title && (
              <Button
                buttonTheme="tertiary"
                icon="arrowRight"
                href={list[0].title.clickThrough.value}
              >
                {list[0].title.linkText}
              </Button>
            )}
            {list && list.length > 1 && (
              <StyledFaqAnchors
                anchors={list.map((group, index) => ({
                  label: group.title.linkText || `Topic ${index}`,
                  index
                }))}
                faqToScroll={faqToScroll}
              />
            )}
          </StyledSidebarWrapper>
        </StyledSidebar>
        {list && (
          <StyledAccordionWrapper $hasMultipleGroup={list.length > 1} ref={contentRef}>
            {list.map(({ questionsGroup, title: { linkText } }, index) => (
              <Accordion
                index={index}
                list={questionsGroup}
                onInViewChange={list.length > 1 ? onInViewChange : undefined}
                key={linkText}
              />
            ))}
          </StyledAccordionWrapper>
        )}
      </StyledSectionInner>
    </StyledSection>
  );
};

export default Faq;
