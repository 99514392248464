import type React from 'react';
import {
  StyledDescription,
  StyledHeading,
  StyledStepNumber,
  StyledSteps,
  StyledStepHeader,
  StyledLine,
  StyledImageWrapper
} from './Steps.styled';
import { TempoCta, TempoImage } from '../../../data/type/content';
import { StyledPhoneImage, StyledStepImage } from '../../block/Stepper/Stepper.styled';

export interface StepsContent {
  image: TempoImage;
  title: TempoCta;
  subtitle: string;
  description: string;
  phoneImage: string;
}

const Steps: React.FC<StepsContent> = ({ subtitle, title, description, image, phoneImage }) => {
  return (
    <StyledSteps>
      <StyledStepHeader>
        <StyledStepNumber type="h4">{title.linkText}</StyledStepNumber>
        <StyledHeading type="h4">{subtitle}</StyledHeading>
      </StyledStepHeader>
      <StyledLine />
      <StyledDescription>{description}</StyledDescription>
      <StyledImageWrapper>
        <StyledStepImage src={image.src} alt={image.src} />
        <StyledPhoneImage src={phoneImage} alt={phoneImage} />
      </StyledImageWrapper>
    </StyledSteps>
  );
};

export default Steps;
