import React from 'react';
import {
  StyledShapeContainer,
  StyledSectionInner,
  StyledSection,
  StyledLine
} from './Banner.styled';
import { CopyContent, TempoCtaGroup, TempoImage } from '../../../data/type/content';
import BaseContent from '../../content/BaseContent/BaseContent';
import { useIsMobileSize } from '../../../hooks/use-is-mobile-size';

export interface BannerProps extends CopyContent {
  image?: TempoImage;
  cardGroup?: Array<TempoCtaGroup>;
}

const Banner: React.FC<BannerProps> = ({
  eyebrow,
  title,
  description,
  cardGroup,
  image = { src: '' }
}) => {
  const isMobileSize = useIsMobileSize();

  return (
    <>
      <StyledSection className="mounted">
        <StyledShapeContainer>
          {isMobileSize ? (
            <>
              <StyledLine name="curlyLine4" />
              <StyledLine name="curvedLine2" />
              <StyledLine name="curvedLine2" />
              <StyledLine name="curlyLine2" />
            </>
          ) : (
            <>
              <StyledLine name="curlyLine6" />
              <StyledLine name="lineArrow1" />
              <StyledLine name="curvedLine3" />
              <StyledLine name="curlyLine5" />
            </>
          )}
        </StyledShapeContainer>
        <StyledSectionInner>
          <BaseContent
            eyebrow={eyebrow}
            title={title}
            description={description}
            ctaGroup={cardGroup}
            isOverBackground={!!image.src}
          />
        </StyledSectionInner>
      </StyledSection>
    </>
  );
};

export default Banner;
