import styled, { css } from 'styled-components';
import { easeOut, rgba, size } from 'polished';
import { Paragraph } from '../Paragraph/Paragraph';
import type { PointerDirection } from './SpeechBubble';
import type { AvatarCircleColor } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { soundBar } from '../../../style/util/animation';
import { StyleProperty } from '../../../data/enum/StyleProperty';
import { pseudo } from '../../../style/util/pseudo';

const radius = '3rem';

const gradientBorder = (direction: string) => css`
  background: linear-gradient(
    ${direction},
    ${({ theme }) => rgba(theme.color.blue, 0.4)} 0%,
    ${({ theme }) => rgba(theme.color.blue, 0.05)} 100%
  );
`;

const gradientBorders = (pointerDirection: string) => css`
  &::before,
  &::after {
    ${pseudo()};
    ${pointerDirection === 'top-left' ? `left: 0;` : `right: 0;`};
  }

  &::before {
    ${size('0.2rem', `calc(100% - ${radius})`)};
    ${gradientBorder(pointerDirection === 'top-left' ? 'to right' : 'to left')};
    top: 0;
  }

  &::after {
    ${size(`calc(100% - ${radius})`, '0.2rem')};
    ${gradientBorder('to bottom')};
    top: 0.2rem;
  }
`;

export const StyledSpeechBubble = styled.div<{
  $avatarColor: AvatarCircleColor;
  $pointerDirection: PointerDirection;
  $enableBackdropFilter: boolean;
  $addGradientBorders: boolean;
  $hasTextAnim: boolean;
  $delay: number;
  $activeSelector: string;
}>`
  display: inline-flex;
  align-items: center;
  padding: 1.8rem 2.4rem;
  background: linear-gradient(98deg, rgba(255, 255, 255, 0.4) -4%, rgba(255, 255, 255, 0.1) 25%);
  box-shadow: 0 0.4rem 3.4rem
    ${({ theme, $avatarColor }) => ($avatarColor === 'white' ? theme.color.blue : theme.color.grey)};
  ${({ $enableBackdropFilter }) => $enableBackdropFilter && `backdrop-filter: blur(5rem);`};
  border-radius: ${({ $pointerDirection }) =>
    `${$pointerDirection === 'top-left' ? '0' : radius} ${
      $pointerDirection === 'top-right' ? '0' : radius
    } ${$pointerDirection === 'bottom-right' ? '0' : radius} ${
      $pointerDirection === 'bottom-left' ? '0' : radius
    }`};

  ${({ $hasTextAnim, $delay, $activeSelector }) =>
    $hasTextAnim &&
    $activeSelector &&
    `
    opacity: 0;
    transform: translateX(6rem);
    transition: opacity 0.3s linear 0.3s, transform 0.5s linear 0.6s, visibility 0s 1s;

    .content.active[data-active-bubble='true'] &,
    ${$activeSelector} & {
      opacity: 1;
      transform: none;
      transition-delay: ${$delay}s, ${$delay}s, 0s;
      transition-timing-function: linear, ${easeOut('back')};
    }
  `};

  ${({ $addGradientBorders, $pointerDirection }) =>
    $addGradientBorders && gradientBorders($pointerDirection)};

  svg {
    display: block;
    ${({ theme, $avatarColor }) => $avatarColor === 'blue' && `color: ${theme.color.blue}`};
  }
`;

function soundBarDelay(delay = 2) {
  let styles = '';
  for (let index: number = 0; index < 5; index += 1) {
    styles += `
       &:nth-of-type(${index + 1}) {
          animation-delay: ${delay + index * 0.2}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const StyledIcon = styled(Icon)<{
  $loopCount: number | 'infinite';
  $activeSelector?: string;
}>`
  flex-shrink: 0;

  svg {
    .bar {
      transform-origin: center;
      animation: ${soundBar} 0.6s linear;
      ${({ $loopCount, $activeSelector }) =>
        $activeSelector &&
        `${$activeSelector} & {animation-iteration-count: ${$loopCount}; border: 1px solid red;}`};
      ${soundBarDelay(2)};
    }
  }
`;

export const StyledParagraph = styled(Paragraph)<{
  $lineBreak: boolean;
  $hasTextAnim?: boolean;
  $activeSelector: string;
}>`
  ${({ $lineBreak }) => !$lineBreak && `white-space: nowrap;`};
  margin-left: 1.2rem;

  .word {
    display: inline-block;

    &:not(:first-of-type) {
      padding-left: 0.2em;
    }
  }

  .char {
    display: inline-block;
  }

  ${({ $hasTextAnim, $activeSelector }) =>
    $hasTextAnim &&
    $activeSelector &&
    `
     margin-bottom: -0.6rem;

     .char {
        opacity: 0;
        max-width: 0;
        overflow: hidden;
        transition: opacity 0.2s, max-width 0.4s ease-out;
        transition-delay: var(${StyleProperty.BubbleCharOutDelay});

        .content.active[data-active-bubble='true'] &,
        ${$activeSelector} & {
          opacity: 1;
          max-width: 1em;
          transition-delay: var(${StyleProperty.BubbleCharInDelay});
        }
     }
  `};
`;
