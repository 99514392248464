/**
 * Aspect ratio, create a :before pseudo element for setting an aspect ratio on an element
 *
 * @param width - Percentage width
 * @param height - Percentage height
 * @param isRelative - Add relative position to element
 */
export const aspectRatio = (width = 1, height = 0, isRelative = true): string => `
  ${isRelative && 'position: relative;'};
  &::before {
    content: '';
    display: block;
    padding-top: ${(height / width) * 100}%;
  }
  `;
