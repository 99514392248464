import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { StyledSpeechBubble, StyledIcon, StyledParagraph } from './SpeechBubble.styled';
import type { AvatarCircleColor } from '../Avatar/Avatar';
import { StyleProperty } from '../../../data/enum/StyleProperty';

export type PointerDirection = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface SpeechBubbleProps {
  text: string;
  avatarColor?: AvatarCircleColor;
  pointerDirection?: PointerDirection;
  enableBackdropFilter?: boolean;
  addGradientBorders?: boolean;
  lineBreak?: boolean;
  hasTextAnim?: boolean;
  textAnimDelay?: number;
  loopCount?: number | 'infinite';
  activeSelector?: string;
  className?: string;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  text,
  avatarColor = 'white',
  pointerDirection = 'top-left',
  enableBackdropFilter = false,
  addGradientBorders = false,
  lineBreak = false,
  hasTextAnim = false,
  textAnimDelay = 0,
  loopCount = 3,
  activeSelector = '',
  className
}) => {
  const speechBubbleRef = useRef<HTMLDivElement>(null);
  const [splitTexts, setSplitTexts] = useState<Array<Array<string>>>();

  useEffect(() => {
    if (!hasTextAnim) {
      return;
    }
    // Split texts
    const texts: Array<Array<string>> = [];
    text.split(' ').forEach((word) => {
      const chars = word.split('');
      texts.push(chars);
    });

    setSplitTexts(texts);
  }, [text, hasTextAnim]);

  useEffect(() => {
    if (!speechBubbleRef.current || !hasTextAnim || !splitTexts) {
      return;
    }

    const chars = speechBubbleRef.current.querySelectorAll('.char');
    const charsLength = chars.length;
    const delay = textAnimDelay + 0.25;
    chars.forEach((char, index) => {
      (char as HTMLElement).style.setProperty(
        StyleProperty.BubbleCharInDelay,
        `${delay + index * 0.02}s`
      );
      (char as HTMLElement).style.setProperty(
        StyleProperty.BubbleCharOutDelay,
        `${(delay + charsLength - index) * 0.01}s`
      );
    });
  }, [splitTexts, hasTextAnim, textAnimDelay]);

  return (
    <StyledSpeechBubble
      $pointerDirection={pointerDirection}
      $enableBackdropFilter={enableBackdropFilter}
      $addGradientBorders={addGradientBorders}
      $avatarColor={avatarColor}
      $hasTextAnim={hasTextAnim}
      $delay={textAnimDelay}
      $activeSelector={activeSelector}
      className={`speech-bubble ${className}`}
      ref={speechBubbleRef}
    >
      <StyledIcon name="sound" $loopCount={loopCount} $activeSelector={activeSelector} />
      <StyledParagraph
        size="small"
        $lineBreak={lineBreak}
        $hasTextAnim={hasTextAnim}
        $activeSelector={activeSelector}
      >
        {!hasTextAnim && text}
        {hasTextAnim &&
          splitTexts &&
          splitTexts.map((words) => (
            <span className="word" key={words.join('')}>
              {words.map((char, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <span className="char" key={`${words.join('')}-${char}-${index}`}>
                  {char}
                </span>
              ))}
            </span>
          ))}
      </StyledParagraph>
    </StyledSpeechBubble>
  );
};

export default SpeechBubble;
