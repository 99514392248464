import styled from 'styled-components';
import { size } from 'polished';
import { device, hover } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { Heading } from '../../general/Heading/Heading';
import { aspectRatio } from '../../../style/util/aspect-ratio';

export const cardWidthMobile = '24.7rem';

export const StyledHeading = styled(Heading)`
  max-width: 24rem;

  @media ${hover} {
    &:hover {
      font-weight: 700;
    }
  }
`;

export const StyledCardWrapper = styled.div`
  ${size('100%')};
  position: absolute;
  top: 0;
  padding: 3rem 5rem 0 3rem;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom-right-radius: 3.2rem;
  box-shadow: 0 1.25rem 2.5rem -0.65rem rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease-out;

  @media ${device.desktop} {
    padding: 4rem 2rem 0 4rem;
  }
`;

export const StyledCard = styled.div`
  ${size(cardWidthMobile)};
  position: relative;

  @media ${hover} {
    cursor: pointer;

    &:hover {
      ${StyledCardWrapper} {
        transform: translateY(-1rem);
        background-color: ${({ theme }) => theme.color.greyLight};
      }

      ${StyledHeading} {
        font-weight: 700;
      }
    }
  }

  @media ${device.desktop} {
    ${size('auto', '100%')};
    ${aspectRatio(1, 1.34)};
    border-bottom-right-radius: 4rem;
  }
`;

export const StyledIconImage = styled.img`
  ${size('2.4rem')};
  margin-bottom: 1rem;

  @media ${device.desktop} {
    ${size('3.2rem')};
    margin-bottom: 1.6rem;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 2rem;
  pointer-events: none;
`;
