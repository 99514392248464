import debounce from 'lodash/debounce';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { position, shapeBounceOut } from './animation-data';
import { spacerHeightVh } from './KeyMessage.styled';
import { ClassList } from '../../../data/enum/ClassList';
import { addClass } from '../../../util/element';

gsap.registerPlugin(ScrollTrigger);

// eslint-disable-next-line no-shadow
enum ScrollIds {
  Messages = 'messages',
  RevealShapes = 'reveal-shapes',
  Collecting = 'collecting'
}

/**
 * Initialize ScrollTrigger
 * @param section
 * @param animContainer
 * @param setCurrentMessageIndex
 */
export function initScrollTrigger(
  section: HTMLElement,
  animContainer: HTMLElement,
  setCurrentMessageIndex: (index: number) => void
): { destroy: () => void } {
  const shapeRows = section.querySelectorAll('.row') || undefined;
  const shapes = section.querySelectorAll('.shape-icon') || undefined;
  const contentElements = section.querySelector('.content') || undefined;
  const containerSize = { width: 0, height: 0 };

  // ---- ScrollTrigger 1 : Three messages (titles) animation --- //
  const messageCount = 3;
  let currentStep = -1;

  const updateMessageClass = (active: boolean) => {
    if (!contentElements) {
      return;
    }
    addClass(contentElements, ClassList.Active, active);
  };

  const onMessageScrollUpdate = ({ progress }: gsap.plugins.ScrollTriggerInstance): void => {
    const step = Math.min(Math.floor(progress * messageCount), messageCount - 1);

    if (currentStep !== step) {
      if (contentElements) {
        (contentElements as HTMLElement).dataset.activeIndex = `${step}`;
        (contentElements as HTMLElement).dataset.activeBubble = `${step >= 2}`;
      }
      currentStep = step;
      setCurrentMessageIndex(step);
    }
  };

  gsap.timeline();
  ScrollTrigger.create({
    trigger: section,
    scrub: 1,
    start: 'top 50%',
    end: `top -${spacerHeightVh}%`,
    onUpdate: onMessageScrollUpdate,
    onEnter: () => updateMessageClass(true),
    onEnterBack: () => updateMessageClass(true),
    onLeaveBack: () => updateMessageClass(false),
    onLeave: () => setCurrentMessageIndex(messageCount),
    id: ScrollIds.Messages
  });

  // ---- ScrollTrigger 2 : Shapes Reveal --- //
  const reveal = gsap.timeline();
  ScrollTrigger.create({
    trigger: section,
    scrub: 1,
    start: `top 0%`,
    end: `top -${spacerHeightVh * 1.25}%`,
    id: ScrollIds.RevealShapes,
    animation: reveal
  });

  shapeRows.forEach((row, index) => {
    reveal.fromTo(row, { x: `${(index === 0 ? 1 : -1) * 100}%` }, { x: '0%' }, 0);
  });

  // ---- ScrollTrigger 3 : Collecting into the bag --- //
  const collecting = gsap.timeline();
  const collectingEnd = window.innerWidth > window.innerHeight ? '123%' : '80%';

  ScrollTrigger.create({
    trigger: section,
    scrub: 1,
    start: `top -${spacerHeightVh}%`,
    end: `bottom ${collectingEnd}`,
    id: ScrollIds.Collecting,
    animation: collecting
  });

  const cols = 9;
  const rows = 2;

  function addCollectingTween() {
    containerSize.height = animContainer.offsetHeight;
    containerSize.width = Math.min(animContainer.offsetWidth, 1500);
    const centerX = containerSize.width * 0.5;
    const centerIndex = Math.floor(cols * 0.5);
    const scale = window.innerWidth < 768 ? 0.85 : 1;

    shapes.forEach((shape, index) => {
      // Clear props first
      gsap.set(shape, { clearProps: 'scale,rotation,x,y' });
      // Set target position
      const { x, width, height } = shape.getBoundingClientRect();
      const { x: parentX } = shape.parentElement?.getBoundingClientRect() || { x: 0 };
      const col = index % cols;
      const row = Math.floor(index / cols);
      const pos = position[index];

      const delay =
        (rows - 1 - row) * 0.12 +
        Math.abs(centerIndex - col) * 0.05 +
        (pos.delay ? pos.delay : Math.random() * 0.2);
      // to center
      let targetX = centerX - x + parentX;
      // add offset x
      targetX += pos.x * width * scale;
      // to bottom
      let targetY = containerSize.height - height * 2;
      // add offset y
      targetY -= pos.y * height * scale;

      collecting.fromTo(
        shape,
        { x: 0 },
        {
          x: `${targetX}px`,
          ease: 'power2.out',
          duration: 0.6,
          delay
        },
        0
      );

      collecting.fromTo(
        shape,
        { y: 0 },
        {
          y: `${targetY}px`,
          rotation: pos.r,
          scale,
          ease: shapeBounceOut,
          duration: 1,
          delay
        },
        0
      );
    });
  }

  addCollectingTween();

  /**
   * onResize
   */
  function onResize() {
    const progress = collecting.progress();
    collecting.clear();
    addCollectingTween();
    collecting.progress(progress);
  }

  const handleResize = debounce(() => {
    onResize();
  }, 300);

  window.addEventListener('resize', handleResize);
  // onResize();

  /**
   * Kill ScrollTriggers
   */
  function killScrollTriggerByIds(ids: Array<string>): void {
    ids.forEach((id) => {
      const trigger = ScrollTrigger.getById(id);
      if (trigger) {
        trigger.kill();
      }
    });
  }

  function destroy(): void {
    killScrollTriggerByIds([ScrollIds.Messages, ScrollIds.RevealShapes, ScrollIds.Collecting]);
    window.removeEventListener('resize', handleResize);
  }

  return {
    destroy
  };
}
