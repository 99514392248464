import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
  StyledExpanded,
  StyledItem,
  StyledItemTab,
  StyledHeading,
  StyledToggleButton
} from './AccordionItem.styled';
import { Paragraph } from '../../../general/Paragraph/Paragraph';
import { TempoFaqItem } from '../../../../data/type/content';

export interface AccordionItemProps extends TempoFaqItem {
  className?: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ className, question, answer }) => {
  const [active, setActive] = useState(false);
  const expandedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const expandedElement = expandedRef.current;
    if (!expandedElement) {
      return;
    }
    const { height: expandedHeight, bottom } = expandedElement.children[0].getBoundingClientRect();
    const height = active ? expandedHeight + 10 : 0;

    gsap.killTweensOf(expandedRef.current);
    gsap.to(expandedRef.current, {
      duration: 0.3,
      ease: 'power2.out',
      height,
      onComplete: () => {
        ScrollTrigger.refresh();
      }
    });

    if (!active) {
      return;
    }
    // scroll if the answer is hidden at the bottom
    const forceScrollY = bottom - (window.innerHeight - 100);
    if (forceScrollY > 0) {
      const scrollTo = window.scrollY + forceScrollY;
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo,
        duration: 0.35
      });
    }
  }, [active]);

  const toggleExpanded = () => {
    setActive(!active);
  };

  return (
    <StyledItem className={`${className} accordion-item`} onClick={toggleExpanded} key={question}>
      <StyledItemTab $active={active}>
        <StyledHeading type="h3">{question}</StyledHeading>
        <StyledToggleButton
          buttonTheme="secondary"
          buttonColor="dark"
          icon="plus"
          $active={active}
        />
      </StyledItemTab>
      <StyledExpanded ref={expandedRef}>
        <Paragraph>{answer}</Paragraph>
      </StyledExpanded>
    </StyledItem>
  );
};

export default AccordionItem;
