import styled, { css } from 'styled-components';
import { size } from 'polished';
import { device } from '../../../style/util/media-query';
import SpeechBubble from '../../general/SpeechBubble/SpeechBubble';
import { transitionFrom, transitionTo } from '../../../style/util/transition';
import { TabsVariantType } from '../../../data/type/content';

export const StyledDialogue = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMiniAvatar = styled.div<{
  $avatarImage: string;
  $transitionOut?: boolean;
  $transitionOutDuration?: number;
  $delay?: number;
}>`
  ${size('3.2rem')};
  background: ${({ $avatarImage }) => `url(${$avatarImage}) center / cover no-repeat;`};
  border-radius: 50%;
  ${({ $transitionOutDuration }) => transitionFrom({ duration: $transitionOutDuration })};
  ${({ $transitionOut, $delay }) =>
    !$transitionOut && transitionTo({ duration: 0.65, delay: $delay || 0 })};

  @media ${device.desktop} {
    ${size('4.8rem')};
  }
`;

export const StyledSpeechBubble = styled(SpeechBubble)<{
  $transitionOut?: boolean;
  $transitionOutDuration?: number;
  $delay?: number;
}>`
  flex-grow: 0;
  ${({ $transitionOutDuration }) => transitionFrom({ duration: $transitionOutDuration })};
  ${({ $transitionOut, $delay }) =>
    !$transitionOut && transitionTo({ duration: 0.65, delay: $delay || 0 })};

  &:not(:first-of-type) {
    margin-top: 0.8rem;
  }

  @media ${device.desktopM} {
    &:not(:first-of-type) {
      margin-top: 1.6rem;
    }
  }
`;

const userSpeechBubbles = css`
  margin-top: 1.6rem;
  max-width: calc(100% - 4rem);
`;

const deviceSpeechBubbles = css`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;

  ${StyledMiniAvatar} {
    border-radius: 0;
    background-size: contain;
  }
`;

export const StyledSpeechBubbles = styled.div<{ $variant: TabsVariantType; $isUser: boolean }>`
  ${({ $isUser }) => ($isUser ? userSpeechBubbles : deviceSpeechBubbles)};
  position: relative;

  @media ${device.desktop} {
    &:not(:first-of-type) {
      margin-top: 2.4rem;
    }

    ${({ $variant, $isUser }) =>
      $variant === `vertical` &&
      `
        max-width: calc(100% - 2.4rem);
        ${!$isUser && `margin-left: 2.2rem;`}
      `};
  }
`;
