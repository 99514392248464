import React, { useContext, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import {
  StyledSection,
  StyledSectionInner,
  StyledHeroContent,
  StyledIcon,
  StyledAvatarContainer,
  StyledAvatar,
  StyledAvatarBlur
} from './HeroHome.styled';
import { CopyContent, TempoStoryWithBubble } from '../../../data/type/content';
import { AvatarIllustration, SpeechBubbleAlign } from '../../general/Avatar/Avatar';
import { useInView } from '../../../hooks/use-in-view';
import { useIsMobileSize } from '../../../hooks/use-is-mobile-size';
import VhTracker from '../../general/VhTracker/VhTracker';
import { ModalContext } from '../../../context/ModalContext';
import { avatarList, avatarPos } from './animation-data';
import { ClassList } from '../../../data/enum/ClassList';

export interface HeroHomeProps extends CopyContent {
  storyGroup: Array<TempoStoryWithBubble>;
}

const transitionDuration = 4;

const HeroHome: React.FC<HeroHomeProps> = ({
  eyebrow,
  title,
  description,
  ctaGroup,
  storyGroup
}) => {
  const avatarContainerRef = useRef<HTMLDivElement>(null);
  const [activeAvatarIndex, setActiveAvatarIndex] = useState<number>(-1);
  const [containerRef, isInView] = useInView({ end: '45%' });
  const [mounted, setMounted] = useState(false);
  const [pauseLoop, setPauseLoop] = useState(false);
  const isMobileSize = useIsMobileSize();
  const { isModalOpen, openModal } = useContext(ModalContext);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 20);
  }, []);

  useEffect(() => {
    setPauseLoop(!isInView || isModalOpen);
  }, [isInView, isModalOpen]);

  useEffect(() => {
    if (pauseLoop) {
      return undefined;
    }

    const delay = activeAvatarIndex < 0 ? 2500 : transitionDuration * 1000;
    const timeoutHandler = setTimeout(() => {
      const index = activeAvatarIndex === storyGroup.length - 1 ? 0 : activeAvatarIndex + 1;
      setActiveAvatarIndex(index);
    }, delay);

    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [pauseLoop, activeAvatarIndex, storyGroup.length]);

  useEffect(() => {
    if (pauseLoop || !avatarContainerRef.current || activeAvatarIndex < 0) {
      return undefined;
    }

    const tl = gsap.timeline();
    const avatars = avatarContainerRef.current.querySelectorAll('.avatar') || undefined;
    avatars.forEach((avatar, index) => {
      const img = avatar.querySelector('.avatar-image');
      const isOn = activeAvatarIndex === index;
      const [x, y, scale] = isOn
        ? avatarPos[index].active
        : avatarPos[index].idle[activeAvatarIndex];
      const posPer = window.innerWidth < 768 ? 25 : 100;

      gsap.to(img, {
        x: `${x * posPer}%`,
        y: `${y * posPer}%`,
        scale,
        duration: isOn ? transitionDuration : 2.8,
        ease: 'power2.out'
      });
    });

    return () => {
      if (tl) {
        tl.kill();
      }
    };
  }, [pauseLoop, activeAvatarIndex]);

  const handleCtaClick = () => {
    openModal();
  };

  return (
    <>
      <VhTracker />
      <StyledSection
        ref={containerRef}
        className={`hero-home ${mounted ? ClassList.Mounted : ''} ${
          isInView ? ClassList.InView : ''
        }`}
      >
        <StyledAvatarContainer ref={avatarContainerRef}>
          {storyGroup.map(
            ({ bubbleGroup: [{ conversation, avatar }] }, index) =>
              conversation && (
                <StyledAvatar
                  image={avatar.src}
                  speech={!isMobileSize ? conversation : ''}
                  speechBubbleAlign={
                    avatarList.foreground[index].speechBubbleAlign as SpeechBubbleAlign
                  }
                  illustration={avatarList.foreground[index].illustration as AvatarIllustration}
                  isActive={index === activeAvatarIndex}
                  playPulse={!pauseLoop && index === activeAvatarIndex}
                  key={conversation}
                  className={`avatar ${index === activeAvatarIndex ? 'active' : ''}`}
                  activeSelector={isInView ? '.active' : ''}
                  addGradientBorders
                  hasTextAnim
                  soundBarLoopCount="infinite"
                  $isActive={index === activeAvatarIndex || activeAvatarIndex === -1}
                />
              )
          )}
          {avatarList.background.map((item) => (
            <StyledAvatarBlur key={`${item.image}-blur`}>
              <img src={item.image} alt={item.image} />
            </StyledAvatarBlur>
          ))}
        </StyledAvatarContainer>
        <StyledSectionInner>
          <StyledHeroContent
            eyebrow={eyebrow}
            title={title}
            description={description}
            cta={ctaGroup ? ctaGroup[0].cta : undefined}
            isHero
            handleCtaClick={handleCtaClick}
          />
          <StyledIcon name="arrowDown" />
        </StyledSectionInner>
      </StyledSection>
    </>
  );
};

export default HeroHome;
