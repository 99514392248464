import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { easeOut, size } from 'polished';
import type { icons } from '../../../data/icons';
import { hover } from '../../../style/util/media-query';

const defaultHover: FlattenSimpleInterpolation = css`
  transition: transform 0.3s ease-out;

  button:hover > &&,
  a:hover > && {
    transform: scale(1.1);
  }
`;

const arrowHover = (direction: string): FlattenSimpleInterpolation => css`
  transition: transform 0.4s ${easeOut('back')};

  button:hover > &&,
  a:hover > && {
    transform: translateX(${direction});
  }
`;

const arrowRightHover: FlattenSimpleInterpolation = css`
  ${arrowHover('0.3rem')};
`;

const arrowLeftHover: FlattenSimpleInterpolation = css`
  ${arrowHover('-0.3rem')};
`;

const iconMap: Partial<Record<keyof typeof icons, FlattenSimpleInterpolation>> = {
  arrowRight: arrowRightHover,
  arrowLeft: arrowLeftHover
};

export const StyledIconWrapper = styled.div<{
  $enableHoverAnim: boolean;
  $icon: keyof typeof icons;
}>`
  ${size('2.4rem')};

  @media ${hover} {
    ${({ $enableHoverAnim, $icon }) =>
      // eslint-disable-next-line no-nested-ternary
      $enableHoverAnim && iconMap[$icon] ? iconMap[$icon] : $enableHoverAnim ? defaultHover : ''};
  }
`;
