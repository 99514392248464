import { ReactElement, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSecondary from '../block/HeroSecondary/HeroSecondary';
import Banner from '../block/Banner/Banner';
import TabsHorizontal from '../block/TabsHorizontal/TabsHorizontal';
import Stepper from '../block/Stepper/Stepper';
import useGlobal, { StoreState } from '../../store';
import { useLoadContent } from '../../hooks/use-load-content';
import { SetupState } from '../../store/module/app';
import { Page } from '../../data/enum/tempo';

export default function SetupPage(): ReactElement {
  const location = useLocation();
  const page: Page = location.pathname === '/siri' ? 'siri' : 'googleAssistant';
  const [value] = useGlobal<SetupState | null, undefined>(
    (state: StoreState) => state[page],
    () => undefined
  );

  useLoadContent(page);

  if (!value) {
    return <></>;
  }

  return (
    <Fragment key={location.pathname}>
      {value.hero && <HeroSecondary {...value.hero} />}
      {value.tabs && <TabsHorizontal {...value.tabs} />}
      {value.setup && <Stepper {...value.setup} />}
      {value.banner && <Banner {...value.banner} />}
    </Fragment>
  );
}
