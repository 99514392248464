import lottie, { AnimationItem } from 'lottie-web';
import { gsap } from 'gsap';
import animDataIntro from '../../../assets/lottie/sound-wave-intro.json';
import animDataIdle from '../../../assets/lottie/sound-wave-idle-loop.json';

export interface LottieAnimItems {
  introAnim: AnimationItem;
  idleAnim: AnimationItem;
}

export function addLottieAnimation(
  intro: HTMLElement,
  idle: HTMLElement,
  loop: HTMLElement
): { destroy: () => void; animItems: LottieAnimItems } {
  const introAnim = lottie.loadAnimation({
    container: intro,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: animDataIntro
  });

  const idleAnim = lottie.loadAnimation({
    container: idle,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: animDataIdle
  });

  gsap.set([idle, loop], { display: 'none' });

  const onLottieIntroAnimComplete = () => {
    if (intro) {
      gsap.set(intro, { display: 'none' });
    }

    if (introAnim.playDirection === 1 && idle) {
      gsap.set(idle, { display: 'block' });
      idleAnim.play();
    }
  };

  introAnim.setSpeed(1);
  introAnim.addEventListener('complete', onLottieIntroAnimComplete);

  function destroy(): void {
    if (introAnim) {
      introAnim.removeEventListener('complete', onLottieIntroAnimComplete);
      introAnim.destroy();
    }

    if (idleAnim) {
      idleAnim.destroy();
    }
  }

  return { destroy, animItems: { introAnim, idleAnim } };
}
