import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 62.5%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    overscroll-behavior-x: none;
  }

  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;

    &:not(.no-smooth-scroll) {
      // scroll-behavior: smooth;
    }
  }

  body {
    font-family: ${theme.font.bogle};
    font-size: 1.6rem;
    color: ${theme.color.ozarkNoir};
    background-color: ${theme.color.white};
  }

  a {
    color: currentColor;
    display: block;
    text-decoration: none;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 100%;
    font-weight: normal;
  }

  p {
    margin: 0;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  figure {
    margin: 0;
  }

  input,
  select {
    border: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  input {
    &:focus {
      outline: none;
    }
  }

  ol,
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .is-draggable {
    cursor: grab;
  }

  .is-dragging {
    cursor: grabbing;
  }

  .disable-scroll {
    overflow: hidden;
  }
`;
