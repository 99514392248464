import styled from 'styled-components';
import {
  typeStyle,
  ParagraphSize,
  TextTransformType,
  TextAlignType
} from '../../../style/type/type-style';

export interface StyledTextProps {
  $textTransform?: TextTransformType;
  $textAlign?: TextAlignType;
  $color?: string;
  $fontWeight?: string;
}

interface StyledParagraphProps extends StyledTextProps {
  $size: ParagraphSize;
}

export const StyledParagraph = styled.p<StyledParagraphProps>`
  ${({ $size }) => typeStyle[$size]};
  ${({ $textAlign }) => $textAlign && `text-align:  ${$textAlign}`};
  ${({ $color }) => $color && `color:  ${$color}`};
  ${({ theme, $textTransform }) =>
    theme.supportsTextCasing && $textTransform && `text-transform:  ${$textTransform}`};
`;
