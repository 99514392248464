import { size } from 'polished';
import styled from 'styled-components';
import { sizes } from '../../../style/util/media-query';
import { Heading } from '../../general/Heading/Heading';
import { zIndex } from '../../../style/util/z-index';
import { Icon } from '../../general/Icon/Icon';

export const StyledPhoneRotation = styled.div`
  display: none;
  position: fixed;

  @media (max-width: ${sizes.tablet - 1}px) and (orientation: landscape) {
    ${size('100%')};
    ${zIndex('modal')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.white};
    top: 0;
    left: 0;
    text-align: center;
  }
`;

export const StyledRotationPhone = styled(Icon)`
  ${size('6.4rem')};
`;

export const StyledRotationSign = styled(Heading)`
  margin: 1.6rem 0;
`;
