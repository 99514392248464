import avatarBlur1 from '../../../assets/images/home/avatar-blur-01.png';
import avatarBlur2 from '../../../assets/images/home/avatar-blur-02.png';
import avatarBlur3 from '../../../assets/images/home/avatar-blur-03.png';

export const avatarPos = [
  {
    active: [0, 0, 1.1],
    idle: [
      [0, 0, 1],
      [-0.1, 0.15, 0.91],
      [0, 0, 0.91],
      [0, 0.2, 0.85]
    ]
  },
  {
    active: [0, 0, 1],
    idle: [
      [0.2, 0, 0.9],
      [0, 0, 1],
      [-0.2, -0.1, 0.8],
      [0, 0, 0.84]
    ]
  },
  {
    active: [0, 0, 1],
    idle: [
      [0.4, 0.1, 0.88],
      [0.55, 0.15, 0.78],
      [0, 0, 1],
      [0.3, 0.2, 0.82]
    ]
  },
  {
    active: [0, 0, 1],
    idle: [
      [-0.2, -0.3, 0.8],
      [0, 0, 0.9],
      [-0.25, -0.05, 0.85],
      [0, 0, 1]
    ]
  }
];

export const avatarList = {
  foreground: [
    {
      speechBubbleAlign: 'right',
      illustration: ''
    },
    {
      speechBubbleAlign: 'left',
      illustration: ''
    },
    {
      speechBubbleAlign: 'left',
      illustration: 'bananas'
    },
    {
      speechBubbleAlign: 'right',
      illustration: 'coffee'
    }
  ],
  background: [
    {
      image: avatarBlur1
    },
    {
      image: avatarBlur2
    },
    {
      image: avatarBlur3
    }
  ]
};
