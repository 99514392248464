import React from 'react';
import {
  StyledContent,
  StyledEyebrow,
  StyledHeading,
  StyledDescription,
  StyledButtonWrapper,
  StyledButton
} from './BaseContent.styled';
import { CopyContent } from '../../../data/type/content';

interface BaseContentProps extends CopyContent {
  isOverBackground?: boolean;
  isHero?: boolean;
  isMobileOnly?: boolean;
  handleCtaClick?: () => void | null;
  className?: string;
}

const BaseContent: React.FC<BaseContentProps> = ({
  eyebrow,
  title,
  description,
  cta,
  ctaGroup,
  isOverBackground = true,
  isHero = false,
  isMobileOnly = false,
  handleCtaClick,
  className
}) => {
  const currentCta = cta || (ctaGroup && ctaGroup[0].cta);
  return (
    <StyledContent
      $hasCta={!!currentCta && !!currentCta.linkText}
      $isOverBackground={isOverBackground}
      className={className}
    >
      {eyebrow && <StyledEyebrow size="eyebrow">{eyebrow}</StyledEyebrow>}
      <StyledHeading type={isHero ? 'h1' : 'h2'} className="title" $isHero={isHero}>
        {title}
      </StyledHeading>
      {description && <StyledDescription>{description}</StyledDescription>}
      {currentCta && (
        <StyledButtonWrapper $isHero={isHero} $isMobileOnly={isMobileOnly}>
          <StyledButton
            buttonColor="dark"
            href={currentCta.clickThrough.type !== 'popup' ? currentCta.clickThrough.value : ''}
            onClick={handleCtaClick}
          >
            {currentCta.linkText}
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </StyledContent>
  );
};

export default BaseContent;
