import type React from 'react';
import { useEffect, useState } from 'react';
import {
  StyledAvatar,
  StyledFigure,
  StyledCircles,
  StyledImage,
  StyledSpeechBubble,
  StyledIllustration
} from './Avatar.styled';
import { Paragraph } from '../Paragraph/Paragraph';

export type AvatarCircleColor = 'white' | 'blue';
export type SpeechBubbleAlign = 'left' | 'right';
export type AvatarIllustration = 'coffee' | 'bananas';

export interface AvatarContent {
  image: string;
  speech?: string;
}

export interface AvatarProps extends AvatarContent {
  color?: AvatarCircleColor;
  useGradient?: boolean;
  speechBubbleAlign?: SpeechBubbleAlign;
  isActive?: boolean;
  playPulse?: boolean;
  activeSelector?: string;
  illustration?: AvatarIllustration;
  soundBarLoopCount?: number | 'infinite';
  hasTextAnim?: boolean;
  enableBackdropFilter?: boolean;
  addGradientBorders?: boolean;
  textAnimDelay?: number;
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  image,
  speech,
  color = 'white',
  useGradient = true,
  speechBubbleAlign = 'left',
  isActive = false,
  playPulse = false,
  activeSelector = '',
  illustration,
  soundBarLoopCount,
  hasTextAnim = false,
  enableBackdropFilter = true,
  addGradientBorders = false,
  textAnimDelay = 0,
  className
}) => {
  const [addPulse, setAddPulse] = useState(false);
  useEffect(() => {
    if (playPulse) {
      setAddPulse(true);
    } else {
      setTimeout(() => {
        setAddPulse(false);
      }, 400);
    }
  }, [playPulse]);

  return (
    <StyledAvatar className={className}>
      <StyledFigure
        className={`avatar-image ${playPulse ? 'pulse' : ''}`}
        $color={color}
        $isActive={isActive}
        $useGradient={useGradient}
      >
        {addPulse && <StyledCircles $out={!playPulse} $color={color} $useGradient={useGradient} />}
        <StyledImage src={image} alt={image} />
      </StyledFigure>
      {speech && (
        <StyledSpeechBubble
          text={speech}
          enableBackdropFilter={enableBackdropFilter}
          addGradientBorders={addGradientBorders}
          avatarColor={color}
          pointerDirection={`top-${speechBubbleAlign}`}
          loopCount={soundBarLoopCount}
          hasTextAnim={hasTextAnim}
          textAnimDelay={textAnimDelay}
          activeSelector={activeSelector}
          $isActive={isActive}
          $align={speechBubbleAlign}
        >
          <Paragraph>{speech && speech}</Paragraph>
        </StyledSpeechBubble>
      )}
      {illustration && (
        <StyledIllustration name={illustration} $icon={illustration} $isActive={isActive} />
      )}
    </StyledAvatar>
  );
};

export default Avatar;
