import styled, { css } from 'styled-components';
import { size } from 'polished';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { zIndex } from '../../../style/util/z-index';
import { fullHeightWithCurved } from '../../../style/util/full-height';
import { shapeWidth, shapeWidthMobile } from '../../general/Shape/Shape.styled';
import { device } from '../../../style/util/media-query';

export const StyledSection = styled(StyledBaseSection)<{ $backgroundImage?: string }>`
  ${zIndex('content')};
  ${({ theme, $backgroundImage }) =>
    $backgroundImage
      ? fullHeightWithCurved
      : `height: 100vh;
         overflow: hidden;
         background-color: ${theme.color.greyLight};`};

  &::before {
    ${({ $backgroundImage }) =>
      $backgroundImage &&
      `background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.2) 16rem),
      url(${$backgroundImage}) center / cover no-repeat`};
  }
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  height: 100%;
`;

export const StyledShapeContainer = styled.div`
  ${size('100%', 'auto')};
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  left: 0;

  @media (min-width: ${parseInt(shapeWidthMobile, 10) * 50}px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media ${device.desktop} {
    width: 100%;
    left: 0;
    transform: none;
  }
`;

const mobileShapes = (width: string) => css`
  &:nth-child(1) {
    top: ${width};

    .shape-icon {
      &:nth-of-type(2) {
        margin-left: calc(${width} * 2.5);
        margin-top: calc(${width} * 0.5);
      }
    }
  }

  &:nth-of-type(2) {
    .shape-icon {
      &:nth-of-type(2) {
        margin-left: ${width};
        margin-right: calc(${width} * 0.5);
      }

      &:nth-of-type(4) {
        margin-right: calc(${width} * -0.5);
      }
    }
  }

  &:nth-of-type(3) {
    .shape-icon {
      &:nth-of-type(2) {
        margin-right: ${width};
      }
    }
  }
`;

const heroMobileShape = '25vw';

export const StyledShapeGroup = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  &:nth-child(1) {
    position: absolute;
  }

  &:nth-of-type(4) {
    display: none;
  }

  @media (max-width: ${parseFloat(shapeWidthMobile) * 50 - 1}px) {
    .shape-icon {
      ${size(heroMobileShape)};

      &.is-half {
        width: calc(${heroMobileShape} * 0.5);
      }
    }

    ${mobileShapes(heroMobileShape)};
  }

  @media (min-width: ${parseFloat(shapeWidthMobile) * 50}px) {
    ${mobileShapes(shapeWidthMobile)};
  }

  @media ${device.desktop} {
    position: absolute;
    height: ${shapeWidth};

    &:nth-child(1),
    &:nth-of-type(4) {
      width: ${shapeWidth};
      top: calc(50% - ${shapeWidth});
    }

    &:nth-child(1) {
      .shape-icon {
        &:nth-of-type(2) {
          // Set opacity to 0 not display none to prevent masking issues to other macaron shapes
          opacity: 0;
        }
      }
    }

    &:nth-of-type(2) {
      justify-content: flex-end;
      left: 0;
      bottom: 0;

      .shape-icon {
        &:nth-of-type(2) {
          margin-left: 0;
          margin-right: calc(${shapeWidth} * 0.5);
        }

        &:nth-of-type(3) {
          display: none;
        }

        &:nth-of-type(4) {
          margin-right: 0;
        }
      }
    }

    &:nth-of-type(3) {
      right: 0;
      bottom: 0;
    }

    &:nth-of-type(4) {
      display: flex;
      flex-direction: column;
      right: 0;
    }
  }

  @media ${device.desktopM} {
    &:nth-of-type(2) {
      .shape-icon {
        &:nth-of-type(2) {
          margin-right: calc(${shapeWidth} * 1.5);
        }
        &:nth-of-type(3) {
          display: block;
          transform: translateX(100%);
        }

        &:nth-of-type(4) {
          transform: translateX(-100%);
        }
      }
    }
  }
`;
