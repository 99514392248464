import styled, { css } from 'styled-components';
import { rgba, size } from 'polished';
import { AbstractButton } from '../AbstractButton/AbstractButton';
import type { ButtonTheme } from './Button';
import { Icon } from '../../Icon/Icon';
import { device, hover } from '../../../../style/util/media-query';
import { Paragraph } from '../../Paragraph/Paragraph';
import { pseudo } from '../../../../style/util/pseudo';

const buttonHeight = '6.4rem';
const buttonHeightMobile = '4.2rem';

export type StyledButtonProps = {
  $buttonTheme: ButtonTheme;
  $iconOnly: boolean;
};

export const StyledLabel = styled(Paragraph)`
  position: relative;
  margin-top: -0.1em;
  text-align: center;
  color: inherit;
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;

  svg {
    position: relative;
  }
`;

export const StyledImage = styled.span<{ $backgroundImage: string }>`
  ${size('2.4rem')};
  flex-shrink: 0;
  background: url(${({ $backgroundImage }) => $backgroundImage}) center / cover no-repeat;
`;

/* ToggleButton Themes */
const primary = css`
  height: ${buttonHeight};
  padding: 0 4rem;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.blue};
  border-radius: calc(${buttonHeight} * 0.5);
  transition: background-color 0.25s ease-out, border 0.25s ease-out;

  ${StyledIcon} {
    color: inherit;
  }

  &[data-color='dark'] {
    background-color: ${({ theme }) => theme.color.ozarkNoir};
  }

  &[data-color='greyLight'] {
    background-color: ${({ theme }) => theme.color.greyLight};
    color: ${({ theme }) => theme.color.ozarkNoir};
  }

  &[data-color='white'] {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.ozarkNoir};
    border: 0.1rem solid ${({ theme }) => theme.color.grey};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.color.grey};
  }

  @media ${hover} {
    &:hover {
      background-color: ${({ theme }) => theme.color.blue};

      &[data-color='dark'] {
        background-color: ${({ theme }) => theme.color.ozarkNoirHover};
      }

      &[data-color='greyLight'] {
        background-color: ${({ theme }) => rgba(theme.color.blue, 0.1)};
      }

      &[data-color='white'] {
        background-color: ${({ theme }) => theme.color.white};
        border: 0.1rem solid ${({ theme }) => theme.color.ozarkNoirHover};
      }
    }
  }
`;

const secondary = css`
  height: ${buttonHeight};
  padding: 0 2.4rem;
  color: ${({ theme }) => theme.color.blue};
  border: 0.1rem solid;
  border-radius: calc(${buttonHeight} * 0.5);
  transition: background-color 0.25s ease-out;

  &[data-color='dark'] {
    color: ${({ theme }) => theme.color.ozarkNoir};
  }

  &[disabled] {
    color: ${({ theme }) => theme.color.grey};
  }

  @media ${hover} {
    &:hover {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.blue};

      &[data-color='dark'] {
        background-color: ${({ theme }) => theme.color.ozarkNoir};
      }
    }
  }
`;

const tertiaryHover = css`
  ${StyledLabel} {
    &::before {
      transform: scaleX(1);
    }
  }

  ${StyledIcon} {
    transform: translateX(0.5rem);
  }

  &[data-color='dark'] {
    ${StyledIcon} {
      background-color: ${({ theme }) => theme.color.ozarkNoir};
    }
  }
`;

const tertiary = css`
  color: ${({ theme }) => theme.color.blue};
  height: ${buttonHeightMobile};

  &[data-color='dark'] {
    color: ${({ theme }) => theme.color.ozarkNoir};
  }

  &[data-color='white'] {
    color: ${({ theme }) => theme.color.white};
  }

  ${StyledIcon} {
    border-radius: 50%;
    padding: 0.3rem;
    border: 0.1rem solid;
    transition: transform 0.3s ease-out;
  }

  ${StyledLabel} {
    &::before {
      ${pseudo()};
      width: 100%;
      bottom: -0.2em;
      border-bottom: 0.1rem solid;
      pointer-events: none;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease-out;
    }
  }

  &.selected {
    ${tertiaryHover};
  }

  @media ${hover} {
    .button:hover &,
    &:hover {
      ${tertiaryHover};
    }
  }

  @media ${device.desktop} {
    height: ${buttonHeight};
  }
`;

/* ToggleButton Theme Map */
const buttonThemeMap: Record<ButtonTheme, typeof primary> = {
  primary,
  secondary,
  tertiary
};

/* ToggleButton base style */
export const StyledButton = styled(AbstractButton)<StyledButtonProps>`
  ${({ $buttonTheme }) => buttonThemeMap[$buttonTheme]};
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${({ $iconOnly }) => $iconOnly && `width: ${buttonHeight}; padding: 0;`};

  &[disabled] {
    pointer-events: none;
  }

  ${StyledLabel} + ${StyledIcon},
  ${StyledLabel} + ${StyledImage} {
    margin-left: 1rem;
    ${({ $buttonTheme }) => $buttonTheme !== 'tertiary' && `margin-right: -0.5rem;`};
  }
`;
