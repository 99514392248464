import styled, { css } from 'styled-components';
import { size } from 'polished';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import { device } from '../../../style/util/media-query';
import FaqAnchors from '../../content/FaqAnchors/FaqAnchors';
import { pseudo } from '../../../style/util/pseudo';
import { zIndex } from '../../../style/util/z-index';
import { fullWidthMargin } from '../../../style/util/full-width';

export const StyledSection = styled(StyledBaseSection)<{ $hasMultipleGroup: boolean }>`
  overflow: visible;
  ${({ $hasMultipleGroup }) => $hasMultipleGroup && `min-height: 100vh;`};
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)`
  @media ${device.desktop} {
    display: grid;
    gap: ${({ theme }) => theme.gridSpacing};
    grid-template-columns: repeat(12, 1fr);
  }
`;

const sidebarMobileHeight = '24rem';

const sidebarWithFilters = css`
  position: sticky;
  top: -4.8rem;
  min-height: ${sidebarMobileHeight};
  pointer-events: none;

  &::before {
    ${pseudo()};
    ${fullWidthMargin};
    height: 12.7rem;
    background-color: ${({ theme }) => theme.color.white};
    left: 0;
    box-shadow: 0 2rem 4rem -1rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }

  &.is-pinned {
    &::before {
      opacity: 1;
    }
  }

  @media ${device.desktop} {
    &::before {
      ${size('100%')};
      box-shadow: 3rem 0 4rem -4rem rgba(0, 0, 0, 0.2);
    }
  }
`;

export const StyledSidebar = styled.div<{ $hasMultipleGroup: boolean }>`
  ${({ $hasMultipleGroup }) => $hasMultipleGroup && sidebarWithFilters};
  ${zIndex('content')};

  @media ${device.desktop} {
    position: relative;
    top: 0;
    grid-column: 1 / span ${({ $hasMultipleGroup }) => ($hasMultipleGroup ? 4 : 3)};
  }
`;

export const StyledSidebarWrapper = styled.div<{ $hasMultipleGroup: boolean }>`
  position: relative;
  pointer-events: auto;

  @media ${device.desktop} {
    ${({ $hasMultipleGroup }) => $hasMultipleGroup && `position: sticky; top: 12rem;`};
  }
`;

export const StyledFaqAnchors = styled(FaqAnchors)`
  margin: 2.6rem -${({ theme }) => theme.sitePaddingHorizontalMobile} 1.8rem;

  .slick-list {
    padding-left: ${({ theme }) => theme.sitePaddingHorizontalMobile};
  }

  @media ${device.desktop} {
    margin: 3.6rem 0;
    padding-left: 0;
  }
`;

export const StyledAccordionWrapper = styled.div<{ $hasMultipleGroup: boolean }>`
  ${({ $hasMultipleGroup }) =>
    $hasMultipleGroup &&
    `
    margin-top: calc(-${sidebarMobileHeight} + 13rem);
  `};
  margin-bottom: 2rem;

  @media ${device.desktop} {
    margin-top: -2.5rem;
    margin-bottom: 0;
    grid-column: ${({ $hasMultipleGroup }) => ($hasMultipleGroup ? 5 : 4)} / -1;
  }
`;
