import React, { useRef, useState } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import { useLocation } from 'react-router-dom';
import Steps, { StepsContent } from '../../content/Steps/Steps';
import { Heading } from '../../general/Heading/Heading';
import iphoneImage from '../../../assets/images/iphone.png';
import androidImage from '../../../assets/images/android.png';
import {
  StyledSideImage,
  StyledButton,
  StyledHeading,
  StyledIcon,
  StyledStepImage,
  StyledSection,
  StyledSectionInner,
  StyledSectionInnerText,
  StyledSlideControls,
  StyledSliderContainer,
  StyledSlider,
  StyledPhoneImage
} from './Stepper.styled';

export interface StepperProps {
  title: string;
  eyebrow: string;
  carouselGroup: Array<StepsContent>;
}

const Stepper: React.FC<StepperProps> = ({ title, eyebrow, carouselGroup: list }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slickRef = useRef<Slider>(null);
  const { pathname } = useLocation();
  const phoneImage = pathname === '/siri' ? iphoneImage : androidImage;

  const responsive: Array<ResponsiveObject> = [
    {
      breakpoint: 1023,
      settings: 'unslick'
    }
  ];

  const settings = {
    arrows: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentIndex(newIndex);
    }
  };

  const handlePreviousClick = () => {
    if (slickRef.current) {
      slickRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (slickRef.current) {
      slickRef.current.slickNext();
    }
  };

  return (
    <StyledSection className={pathname === '/siri' ? 'siri' : 'google-assistant'}>
      <StyledSectionInner>
        <StyledSideImage>
          <StyledStepImage src={list[currentIndex].image.src} alt={list[currentIndex].image.alt} />
          <StyledPhoneImage src={phoneImage} alt={phoneImage} />
        </StyledSideImage>
        <StyledSectionInnerText>
          <StyledHeading>
            <Heading type="h5">{eyebrow}</Heading>
            <Heading type="h2">{title}</Heading>
          </StyledHeading>
          <StyledIcon name="arrowDown" />
          <StyledSliderContainer>
            <StyledSlider {...settings} ref={slickRef}>
              {list.map((item) => (
                <Steps key={item.title.linkText} {...item} phoneImage={phoneImage} />
              ))}
            </StyledSlider>
          </StyledSliderContainer>
          <StyledSlideControls>
            <StyledButton
              buttonTheme="secondary"
              icon="arrowLeft"
              disabled={currentIndex === 0}
              onClick={handlePreviousClick}
            />
            <StyledButton
              buttonTheme="secondary"
              icon="arrowRight"
              disabled={currentIndex === list.length - 1}
              onClick={handleNextClick}
            />
          </StyledSlideControls>
        </StyledSectionInnerText>
      </StyledSectionInner>
    </StyledSection>
  );
};

export default Stepper;
