import { css, FlattenSimpleInterpolation } from 'styled-components';

export type ZindexItem = 'background' | 'default' | 'content' | 'menu' | 'modal';

export const zIndexList: Record<ZindexItem, number> = {
  background: -1,
  default: 0,
  content: 1,
  menu: 9,
  modal: 10001
};

export const zIndex = (indexItem: ZindexItem = 'default'): FlattenSimpleInterpolation => css`
  z-index: ${zIndexList[indexItem]};
`;
