import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { easeOut, size } from 'polished';
import { zIndex } from '../../../style/util/z-index';
import { device, hover, sizes } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { Icon } from '../../general/Icon/Icon';
import { pseudo } from '../../../style/util/pseudo';
import type { NavThemeColor } from './Navigation';

export const StyledNavigation = styled.nav<{ $isOpen: boolean }>`
  ${({ theme }) => size(theme.topNavHeightMobile, '100%')}
  ${zIndex('menu')};
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 ${({ theme }) => theme.sitePaddingHorizontalMobile};
  pointer-events: none;

  @media (max-width: ${sizes.desktop - 1}px) {
    &::before {
      ${pseudo()};
      ${size('100vh', '100%')};
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.color.ozarkNoir};
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s, visibility 0s 0.2s;
    }

    ${({ $isOpen }) =>
      $isOpen &&
      `
        &::before {
          visibility: visible;
          transition-delay: 0s;
          opacity: 0.6;
        }
    `};
  }

  @media ${device.desktop} {
    height: ${({ theme }) => theme.topNavHeight};
    padding: 0 5rem;
  }
`;

export const StyledNavigationWrapper = styled.div<{ $color: NavThemeColor }>`
  ${size('100%')};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  padding: inherit;
  color: ${({ theme }) => theme.color.white};
  pointer-events: auto;
  transition: transform 0.4s ease-out, background-color 0.4s ease-out;

  .scroll-down & {
    transform: translateY(-100%);
  }

  :not(.scroll-down &) {
    ${({ theme, $color }) =>
      $color === 'blue' &&
      `background-color: ${theme.color.greyLight}; color: ${theme.color.blue};`};
  }
`;

export const StyledLogo = styled(Link)`
  ${size('2.6rem', '15rem')};
  ${zIndex('content')}
  position: absolute;
`;

export const StyledLogoIcon = styled(Icon)<{ $isOpen: boolean }>`
  ${size('100%')};
  color: ${({ theme, $isOpen }) => ($isOpen ? theme.color.blue : 'inherit')};
  transition: color 0.5s;
`;

export const StyledNavigationMenus = styled.ul<{ $isOpen: boolean }>`
  ${size('100vh', '100%')};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem ${({ theme }) => theme.sitePaddingHorizontalMobile} 10rem;
  background-color: ${({ theme }) => theme.color.greyLight};

  @media (max-width: ${sizes.desktop - 1}px) {
    align-items: flex-start;
    transform: translateX(100%);
    transition: transform 0.3s ${easeOut('quad')};
    ${({ $isOpen }) => $isOpen && `transform: translateX(0); transition-duration: 0.45s;`};
  }

  @media ${device.desktop} {
    position: relative;
    height: auto;
    flex-direction: row;
    padding: 0;
    background: none;
  }
`;

export const StyledNavigationMenu = styled.li`
  position: relative;
  margin: 3.15vh 0;

  @media (max-width: ${sizes.desktop - 1}px) {
    width: 60%;
  }

  @media ${device.desktop} {
    margin: 0 3rem;
  }

  @media ${device.desktopM} {
    margin: 0 4rem;
  }
`;

export const StyledMenuButton = styled(Button)<{ $isSelected: boolean; $color: NavThemeColor }>`
  ${({ $isSelected }) =>
    $isSelected &&
    `
    pointer-events: none;

    &::before {
      ${pseudo()};
      font-size: 1.6rem;
      border-top: 0.35em solid transparent;
      border-bottom: 0.35em solid transparent;
      border-left: 0.35em solid;
      top: calc(50% - 0.4em);
      left: -0.7em;
    }
  `};

  @media (max-width: ${sizes.desktop - 1}px) {
    height: auto;

    span,
    p {
      font-size: 3.2rem;
      text-align: left;
      line-height: 1.25;
    }
  }

  @media ${device.desktop} {
    padding: 0 1rem;
    color: inherit;

    :not(.scroll-down &) {
      ${({ theme, $color }) => $color === 'blue' && `color: ${theme.color.blue};`};
    }

    ${({ $isSelected }) =>
      $isSelected &&
      `
      &::before {
        font-size: 1rem;
        border-left: 0.35rem solid transparent;
        border-right: 0.35rem solid transparent;
        border-bottom: 0.5rem solid;
        top: 70%;
        left: calc(50% - 0.3em);
      }
      `};
  }
`;

export const StyledStartedButton = styled(Button)`
  position: absolute;
  right: 5rem;
  top: 1.8rem;
  pointer-events: auto;
  transition: transform 0.4s ease-out, background-color 0.3s;

  &:not(.is-sticky) {
    ${({ theme }) => `transform: translateY(-${theme.topNavHeight})`};
  }

  &.is-sticky {
    transform: none;
  }

  .blue-background & {
    background-color: ${({ theme }) => theme.color.ozarkNoir};
  }
`;

export const StyledToggleButton = styled(Button)<{ $isOpen: boolean }>`
  position: absolute;
  right: 0;
  pointer-events: auto;
  background-color: transparent;
  color: inherit;

  path {
    transform-origin: 0.75rem 1.2rem;
    transition: transform 0.3s;
  }

  @media ${hover} {
    &:hover {
      background-color: transparent;
    }
  }

  ${({ theme, $isOpen }) =>
    $isOpen &&
    `
    color: ${theme.color.blue};

    path {
      &:first-child {
        transform: rotate(45deg) scale(0.7);
      }
       &:last-child {
        transform: rotate(-45deg) scale(0.7);
      }
    }
  `};
`;

export const StyledMenusBackground = styled.li`
  ${size('100%')};
  position: absolute;
  left: 0;
  overflow: hidden;

  > div {
    position: absolute;

    &:nth-of-type(1) {
      right: 0;
      top: 23.5%;
      transform: rotate(-15deg) translateX(10%);
    }

    &:nth-of-type(2) {
      right: 0;
      top: 59.5%;
      transform: translateX(20%);
    }

    &:nth-of-type(3) {
      left: 0;
      top: 84.5%;
      transform: translateX(-30%);
    }
  }
`;
