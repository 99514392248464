import { gsap } from 'gsap';
import CustomEase from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

export interface Position {
  index: number;
  x: number;
  y: number;
  r?: number | string;
  delay?: number;
}

export const shapeBounceOut = CustomEase.create(
  'custom',
  'M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.37,0.985 0.422,0.879 0.488,0.844 0.55,0.81 0.6,0.824 0.634,0.86 0.694,0.924 0.719,0.981 0.726,0.998 0.788,0.914 0.84,0.936 0.859,0.95 0.878,0.964 0.897,0.985 0.911,0.998 0.922,0.994 0.939,0.984 0.954,0.984 0.969,0.984 1,1 1,1 '
);

export const position: Array<Position> = [
  // row 1
  { index: 0, x: -0.25, y: 2.315, r: 233, delay: 0.2 },
  { index: 1, x: 0.94, y: 1.055 },
  { index: 2, x: 0.26, y: 0.99 },
  { index: 3, x: -0.36, y: 1.64, r: 130 },
  { index: 4, x: 2.4, y: 1.28, r: 145 },
  { index: 5, x: -1.69, y: 0.45, r: 62 },
  { index: 6, x: -0.7, y: 0.73, r: '320-cw' },
  { index: 7, x: -1.22, y: 1.55, delay: 0.19 },
  { index: 8, x: 0.87, y: 1.96, delay: 0.195 },
  // row 2
  { index: 9, x: -1.4, y: 0.76, r: 22 },
  { index: 10, x: -0.28, y: 0.83, r: -28 },
  { index: 11, x: 0.68, y: 1.08, r: 62 },
  { index: 12, x: -1, y: 0.15, r: 0 },
  { index: 13, x: 0.2, y: -0.08, r: 225 },
  { index: 14, x: -2.37, y: 0.75, r: 62 },
  { index: 15, x: -3.1, y: 0.24, r: '-40-cw' },
  { index: 16, x: 2.415, y: 0.42, r: 67 },
  { index: 17, x: 1, y: 1.36, r: 120 }
];
