import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { size } from 'polished';
import { Heading } from '../../general/Heading/Heading';
import { device, sizes } from '../../../style/util/media-query';
import { Button } from '../../general/buttons/Button/Button';
import { fluidType } from '../../../style/util/fluid-size';
import { zIndex } from '../../../style/util/z-index';
import { Paragraph } from '../../general/Paragraph/Paragraph';
import { transition } from '../../../style/util/transition';
import { ClassList } from '../../../data/enum/ClassList';

const contentTransition = (inDelay = 0): FlattenSimpleInterpolation => css`
  ${transition({ selector: `.${ClassList.Mounted}`, inDelay })};
`;

export const StyledContent = styled.div<{ $hasCta?: boolean; $isOverBackground?: boolean }>`
  ${size('100%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  ${({ theme, $isOverBackground }) =>
    $isOverBackground ? `color: ${theme.color.white};` : `h1 {color: ${theme.color.blue}};`};

  @media ${device.desktop} {
    align-items: center;
  }
`;

export const StyledEyebrow = styled(Paragraph)`
  margin-bottom: 1rem;
  ${contentTransition()};

  @media ${device.desktop} {
    margin-bottom: 1.8rem;
  }
`;

export const StyledHeading = styled(Heading)<{ $isHero: boolean }>`
  ${({ $isHero }) => $isHero && fluidType(44, 72)};
  width: 9.3em;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${contentTransition(0.15)};

  @media ${device.tablet} {
    width: 9.3em;
  }

  @media ${device.desktop} {
    &:first-child {
      margin-top: 4.3rem;
    }
  }
`;

export const StyledDescription = styled(Paragraph)`
  margin-top: 2rem;
  ${contentTransition(0.3)};

  @media ${device.desktop} {
    margin-top: 3rem;
  }
`;

export const StyledButton = styled(Button)`
  position: relative;
`;

export const heroButtonWrapper = css`
  ${zIndex('menu')};
  position: fixed;
  margin-top: 2rem;

  ${StyledButton} {
    width: 100%;
  }

  @media (max-width: ${sizes.desktop - 1}px) {
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem ${({ theme }) => theme.sitePaddingHorizontalMobile};
    background: linear-gradient(97deg, rgba(255, 255, 255, 0.4) -4%, rgba(255, 255, 255, 0.1) 5%);
    box-shadow: 0 0.4rem 3.4rem rgba(92, 92, 92, 0.3);
    backdrop-filter: blur(5rem);
  }

  @media ${device.desktop} {
    ${StyledButton} {
      width: auto;
    }
  }
`;

export const StyledButtonWrapper = styled.div<{ $isHero: boolean; $isMobileOnly: boolean }>`
  ${({ $isHero }) => $isHero && heroButtonWrapper};
  ${({ $isHero }) => !$isHero && `margin-top: 3.2rem;`};
  ${contentTransition(0.4)};

  @media ${device.desktop} {
    position: relative;
    margin-top: 3.2rem;
    ${({ $isMobileOnly }) => $isMobileOnly && `display: none;`};
  }
`;
