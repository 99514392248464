/* Media queries */
export const sizes = {
  mobileM: 375,
  mobileL: 414,
  tablet: 768,
  desktop: 1024,
  desktopM: 1280,
  desktopL: 1440,
  desktopXl: 1920
};

export const device = {
  mobileM: `(min-width: ${sizes.mobileM}px)`,
  mobileL: `(min-width: ${sizes.mobileL}px)`,
  tablet: `(min-width: ${sizes.tablet}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
  desktopM: `(min-width: ${sizes.desktopM}px)`,
  desktopL: `(min-width: ${sizes.desktopL}px)`,
  desktopXl: `(min-width: ${sizes.desktopXl}px)`
};

export const hover = '(hover: hover)';
