import styled from 'styled-components';
import { size } from 'polished';
import { device } from '../../../style/util/media-query';
import { StyledBaseSection, StyledBaseSectionInner } from '../../styled/BaseSection.styled';
import TabBar from '../../content/TabBar/TabBar';
import TabContent from '../../content/TabContent/TabsContent';
import { grid } from '../../../style/util/grid';
import { StyledTitle } from '../TabsHorizontal/TabsHorizontal.styled';
import ShapeLine from '../../general/ShapeLine/ShapeLine';
import { Icon } from '../../general/Icon/Icon';

export const StyledSection = styled(StyledBaseSection)`
  overflow: visible;
`;

export const StyledSectionInner = styled(StyledBaseSectionInner)``;

export const StyledTabsContainer = styled.div`
  width: 100%;

  @media ${device.desktop} {
    ${grid()};
  }
`;

export const StyledTabBarWrapper = styled.div`
  @media ${device.desktop} {
    grid-column: 1 / span 1;
    margin-left: -6rem;
  }

  @media ${device.desktopM} {
    grid-column: 1 / span 2;
    margin-left: 0;
  }
`;

export const StyledTabBar = styled(TabBar)`
  position: sticky;
  top: 50%;
  margin-top: calc(15rem + 10vw);
  margin-bottom: 15vh;
  transform: translateY(-50%);
`;

export const StyledTitleVertical = styled(StyledTitle)`
  @media ${device.desktop} {
    max-width: 48rem;
  }
`;

export const StyledArrow = styled(Icon)`
  ${size('4.2rem')};
  position: relative;
  margin: 4.8rem auto -1rem;
  color: ${({ theme }) => theme.color.blue};

  @media ${device.desktop} {
    display: none;
  }
`;

export const StyledTabContentContainer = styled.div<{ $reverse?: boolean }>`
  margin: 3rem 0 0;

  @media ${device.desktop} {
    grid-column: 2 / -1;
  }

  @media ${device.desktopM} {
    grid-column: 3 / -1;
    margin-top: 5.5rem;
  }
`;

export const StyledTabContent = styled(TabContent)`
  padding: 3rem 0;

  &:last-of-type {
    padding-bottom: 0;
  }

  @media ${device.desktop} {
    ${grid(11)};
    padding-top: 5.875rem;

    .column {
      &:nth-child(1) {
        grid-column: span 3;
      }

      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 4;
      }
    }
  }

  @media ${device.desktopM} {
    ${grid(10)};

    .column {
      &:nth-child(3) {
        grid-column: span 3;
      }
    }
  }
`;

export const StyledBackground = styled.div`
  ${size('50%', '100%')};
  position: absolute;
  display: none;
  left: 0;
  overflow: hidden;

  @media ${device.desktop} {
    display: block;
    top: 6rem;
  }

  @media ${device.desktopM} {
    top: 11rem;
  }
`;

export const StyledLine = styled(ShapeLine)`
  color: ${({ theme }) => theme.color.blue};

  &:nth-of-type(1) {
    width: 19.5vw;
    max-width: 38.3rem;
    top: 10rem;
    left: 0;
    transform-origin: 0 20%;
    transform: scaleY(-1) rotate(15deg);
  }

  &:nth-of-type(2) {
    width: 20vw;
    max-width: 39.6rem;
    top: 16rem;
    left: 0;
    transform-origin: left 10%;
    transform: rotate(-19deg);
  }

  &:nth-of-type(3) {
    width: 24vw;
    max-width: 46rem;
    top: 0;
    right: 0;
  }

  &:nth-of-type(4) {
    width: 18vw;
    max-width: 35.5rem;
    top: 6rem;
    right: 0;
    transform-origin: top right;
    transform: rotate(-22deg) translateX(5%);
  }
`;
