import React from 'react';
import { StyledButton, StyledIcon, StyledImage, StyledLabel } from './Button.styled';
import type { icons } from '../../../../data/icons';

export type ButtonTheme = 'primary' | 'secondary' | 'tertiary';
export type ButtonColor = 'default' | 'dark' | 'white' | 'greyLight';

export type ButtonProps = {
  icon?: keyof typeof icons;
  label?: string;
  image?: string;
  buttonTheme?: ButtonTheme;
  buttonColor?: ButtonColor;
  disabled?: boolean;
} & React.ComponentProps<'a'> &
  React.ComponentProps<'button'>;

export const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    { children, icon, image, buttonTheme = 'primary', buttonColor = 'default', onClick, ...props },
    ref
  ) => {
    return (
      <StyledButton
        {...props}
        className={`${props.className}`}
        ref={ref}
        $buttonTheme={buttonTheme}
        $iconOnly={!children}
        data-color={buttonColor}
        onClick={onClick}
      >
        {children && (
          <StyledLabel type="span" size="small">
            {children}
          </StyledLabel>
        )}
        {icon && <StyledIcon name={icon} type="span" enableHoverAnim />}
        {!icon && image && <StyledImage $backgroundImage={image} />}
      </StyledButton>
    );
  }
);

Button.displayName = 'button';
