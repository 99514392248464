import type React from 'react';
import { StyledIcon } from './Shape.styled';
import { icons } from '../../../data/icons';

export type ShapeColor = 'ozarkNoir' | 'blue' | 'yellow' | 'white';

export interface ShapeProps {
  icon: keyof typeof icons;
  color?: ShapeColor;
  noStroke?: boolean;
  rotation?: string;
  className?: string;
}

const Shape: React.FC<ShapeProps> = ({
  icon,
  color = 'blue',
  noStroke = false,
  rotation,
  className
}) => {
  return (
    <StyledIcon
      className={`${className} shape-icon ${icon === 'shapeMacaron' && 'is-half'}`}
      name={icon}
      $color={color}
      $isHalf={icon === 'shapeMacaron'}
      $icon={icon}
      $noStroke={noStroke}
      $rotation={rotation}
    />
  );
};

export default Shape;
