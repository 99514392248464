import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { StyledAccordion } from './Accordion.styled';
import AccordionItem from '../AccordionItem/AccordionItem';
import { TempoFaqItem } from '../../../../data/type/content';
import { useInView } from '../../../../hooks/use-in-view';

gsap.registerPlugin(ScrollToPlugin);

export interface AccordionProps {
  className?: string;
  index: number;
  list: Array<TempoFaqItem>;
  onInViewChange?: (index: number, inView: boolean) => void;
}

const Accordion: React.FC<AccordionProps> = ({ className, index, list, onInViewChange }) => {
  const [containerRef, isInView] = useInView({ start: 'top 150px', forwardOnly: true });

  useEffect(() => {
    if (onInViewChange) {
      onInViewChange(index, isInView);
    }
  }, [index, isInView, onInViewChange]);

  return (
    <StyledAccordion
      className={`${className} accordion`}
      ref={onInViewChange ? containerRef : null}
    >
      {list.map((item) => (
        <AccordionItem key={`${item.question}${item.uid}`} {...item} />
      ))}
    </StyledAccordion>
  );
};

export default Accordion;
