import React from 'react';
import {
  StyledPhoneRotation,
  StyledRotationPhone,
  StyledRotationSign
} from './RotateYourPhone.styled';
import { Paragraph } from '../../general/Paragraph/Paragraph';

export interface RotateYourPhoneProps {
  className?: string;
}

const RotateYourPhone: React.FC<RotateYourPhoneProps> = () => {
  return (
    <StyledPhoneRotation>
      <StyledRotationPhone name="rotatePhone" />
      <StyledRotationSign type="h2">
        Please rotate
        <br />
        your device!
      </StyledRotationSign>
      <Paragraph>
        The page is best viewed in <br />
        Portrait orientation
      </Paragraph>
    </StyledPhoneRotation>
  );
};

export default RotateYourPhone;
