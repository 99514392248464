import { css } from 'styled-components';
import { device } from './media-query';

export const fullWidth = css`
  width: calc(100% + (${({ theme }) => theme.sitePaddingHorizontalMobile} * 2));

  @media ${device.desktop} {
    width: calc(100% + (${({ theme }) => theme.sitePaddingHorizontal} * 2));
  }
`;

export const fullWidthMargin = css`
  ${fullWidth};
  margin-left: -${({ theme }) => theme.sitePaddingHorizontalMobile};

  @media ${device.desktop} {
    margin-left: -${({ theme }) => theme.sitePaddingHorizontal};
  }
`;

export const fullWidthMarginMobile = css`
  ${fullWidth};
  margin-left: -${({ theme }) => theme.sitePaddingHorizontalMobile};
`;
