import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ModalContainer from '../ModalContainer/ModalContainer';
import { ModalContext } from '../../../../context/ModalContext';

export const Modal: React.FC = () => {
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  useEffect(() => {
    // Close modal on pathname changes if it is open
    if (isModalOpen) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!isModalOpen) {
    return <></>;
  }

  return <ModalContainer />;
};
