import { keyframes } from 'styled-components';

export const avatarPulse = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  33% {
    transform: scale(1.34);
    opacity: 1;
  }

  58% {
    transform: scale(1.5);
  }

  70% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;

export const avatarPulseInner = keyframes`
  0% {
    opacity: 0.7;
    transform: scale(1.08);
  }

  30% {
    opacity: 1;
    transform: scale(1.3);
  }

  45% {
    opacity: 1;
    transform: scale(1.24);
  }

  63% {
    opacity: 1;
    transform: scale(1.34);
  }

  100% {
    opacity: 0.7;
    transform: scale(1.08);
  }
`;

export const avatarPulseOuter = keyframes`
  0% {
    opacity: 0.7;
    transform: scale(1.15);
  }

  30% {
    opacity: 1;
    transform: scale(1.6);
  }

  45% {
    opacity: 1;
    transform: scale(1.55);
  }

  60% {
    opacity: 1;
    transform: scale(1.68);
  }

  100% {
    opacity: 0.7;
    transform: scale(1.15);
  }
`;

export const soundBar = keyframes`
  0% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(0.7);
  }

  80% {
    transform: scaleY(1.2);
  }

  100% {
    transform: scaleY(1);
  }
`;
