import React from 'react';
import {
  StyledContent,
  StyledEyebrow,
  StyledHeading,
  StyledDescription,
  StyledButtonWrapper,
  StyledButton,
  StyledButtonClose,
  StyledButtonCloseMobile
} from './ChooseAssistant.styled';
import { CopyContent } from '../../../data/type/content';
import useGlobal, { StoreState } from '../../../store';
import { toCamel } from '../../../util/string';
import { icons } from '../../../data/icons';

export interface ChooseAssistantProps {
  handleCloseClick?: () => void | null;
  className?: string;
}

const ChooseAssistant: React.FC<ChooseAssistantProps> = ({ handleCloseClick, className }) => {
  const [modal] = useGlobal<CopyContent | null | undefined, undefined>(
    (state: StoreState) => state.home?.modal,
    () => undefined
  );

  if (!modal) {
    return <></>;
  }

  return (
    <StyledContent className={className}>
      {modal.eyebrow && <StyledEyebrow size="eyebrow">{modal.eyebrow}</StyledEyebrow>}
      <StyledHeading type="h3" className="title">
        {modal.title}
      </StyledHeading>
      {modal.description && <StyledDescription size="small">{modal.description}</StyledDescription>}
      <StyledButtonWrapper>
        {modal.ctaGroup &&
          modal.ctaGroup.map(({ cta, icon }) => (
            <StyledButton
              buttonColor="white"
              icon={
                !icon?.src ? (toCamel(cta.clickThrough.value) as keyof typeof icons) : undefined
              }
              image={icon?.src}
              href={cta.clickThrough.value}
              key={cta.linkText}
            >
              {cta.linkText}
            </StyledButton>
          ))}
      </StyledButtonWrapper>
      <StyledButtonClose icon="close" onClick={handleCloseClick} />
      <StyledButtonCloseMobile onClick={handleCloseClick} />
    </StyledContent>
  );
};

export default ChooseAssistant;
